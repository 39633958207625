import { createTheme } from '@mui/material';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 750, //default 600
      md: 1100, //default 900
      lg: 1400, //default 1300
      xl: 1600, //default 1536
    },
  },
  palette: {
    primary: {
      main: '#1474A4'
    },
    secondary: {
      main: '#FFFFFF'
    },
    warning: {
      main: '#F7D368'
    },
    success: {
      main: '#008000'
    }
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '0 !important',
          display: 'flex'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '20px !important'
        }
      }
    }
  }
})
