import { TextField } from '@mui/material'
import React from 'react'
import { IInputFieldProps } from '../../../types'

/**
 * This component returns JSX element
 * @param {IInputFieldProps} props Input field Props
 * @returns {React.JSX.Element} Text field
 */
export const TextFieldInput = ( props: IInputFieldProps )=>{
  return <TextField 
    id={ props.code + '-textfield' }
    size={ props.size } 
    fullWidth
    label={ props.label } 
    variant="outlined"
    onChange={ ( event ) => props.handleInputChange( props.code, event.target.value ) } 
    disabled={ props.disabled }
    value = { props.value ? props.value : '' }
  />
}