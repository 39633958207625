import { createSlice } from '@reduxjs/toolkit';
import { IDialogState } from '../../../types';

const initialState: IDialogState = {
  show: false,
  type: '',
  data:null,
  handleClickOk: ()=>{}
}

const DialogSlice = createSlice( {
  name: 'dialog',
  initialState,
  reducers: {

    //return true to open the dialog
    openDialog( state, action ) {
      return action.payload;
    },

    //return false to close the dialog
    closeDialog() {
      return initialState;
    }
  },
} )

export const { openDialog, closeDialog } = DialogSlice.actions

export default DialogSlice.reducer