import { handleError } from '../services/DataHelperFunctions';
import ApiUrls from '../services/APIUrl';
import AxiosClient from './AxiosClient';
import { IReadProducts } from '../../types/IRequestTypes';

export const ProductApi = {  

  //This method returns the work item list 
  getProductModels: ( action: IReadProducts ) => {
    let queryParams = 'page=' + action.page + '&limit=' + action.limit;
    queryParams = ( action.productSearch ? 'search=' + action.productSearch + '&' : '' ) + queryParams;
    return AxiosClient
      .get( ApiUrls.readProductModels + `?${queryParams}` )
      .then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          data: response.data,
          page: action.page,
          limit: action.limit,
          totalRecords: total,
          searchKey: action?.productSearch
        }
      } ).catch( ( err ) => {
        return handleError( err )
      } )
  }
}


