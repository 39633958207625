import React, {useEffect, useState, ReactNode} from "react";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ISection } from "../../../types";
import { useSelector } from 'react-redux';
import { AppState } from '../../store/AppStore';
import { getActiveBreadCrumb } from "../../services/CategoryHelper";

const BreadCrumbComponent = ({ breadcrumb, navigateTo }: {breadcrumb: ISection, navigateTo: (section: ISection) => void }) => {
    let breadcrumbList = {...breadcrumb};
    const [crumb, setCrumb] =useState(breadcrumbList);

    const assigned = useSelector((state: AppState) => state.category.assigned);
    
    const navigateToSection = (section: ISection) => {
        navigateTo(section);
    }

    const setBreadCrum = async () => {
        if(breadcrumbList.sections != undefined && breadcrumbList.sections.length > 0) {
            const filteredSection = await getActiveBreadCrumb(breadcrumbList, assigned);
            breadcrumbList.sections = filteredSection;
        }
        setCrumb(breadcrumbList);
    };

    useEffect(() => {
        setBreadCrum();              
    }, [breadcrumb]);

    const getBreadCrumb = (breadcrumbItem: ISection): ReactNode => {
        const nestedBreadCrumb = breadcrumbItem?.sections != undefined && breadcrumbItem?.sections.length > 0;

        return (
            <>
                <Link key={breadcrumbItem?.id} to='' onClick={() => navigateToSection(breadcrumbItem)}
                    className={breadcrumbItem?.active ? "breadcrumb-active breadcrumb-link" : "breadcrumb-not-active breadcrumb-link"}>
                    {breadcrumbItem?.name}
                    {nestedBreadCrumb && <ChevronRightIcon className="bradcrumb-icon" />}
                </Link>
                {
                    nestedBreadCrumb && breadcrumbItem.sections.length == 1 && getBreadCrumb(breadcrumbItem.sections[0])
                }
            </>
        )
    }

    return (
        <div>
            <Card variant="outlined" className="breadcrumb-card-section">
                <nav>
                    {
                        getBreadCrumb(crumb?.id ? crumb : breadcrumbList)
                    }
                </nav>
            </Card>
        </div>
    )
}

export default BreadCrumbComponent;