import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react';
import { DefaultAssignmentIcon } from '../data/Constants';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@mui/icons-material/Refresh';

//Tree Parent icon
export const AddCircle = () =>{
  return <AddCircleIcon className="defaultExpandIcon zoom80"/>
}
  
//Tree Children icon
export const RemoveCircle = () => {
  return <RemoveCircleIcon className="defaultCollapseIcon zoom80" />
}
  
//tree last level icon
export const RemoveCircleEnd = () => {
  return <RemoveCircleIcon className="defaultEndIcon zoom80" />
}

//Default assignment icon
export const getDefaultIcon = ( associations:{ [key: string]: string | null}, defaultValue: string | null, code: string, t ) => {
  if( !associations?.hasOwnProperty( code ) && defaultValue  
      || associations?.hasOwnProperty( code ) && associations[code] === defaultValue ) {
    return <Grid item title={ t( 'labels.defaultAssignment' ) } className="defaultIcon"> {DefaultAssignmentIcon}</Grid>
  }
  return <></>
}

//Refresh Button
export const RefreshButton = ( props:{ handleRefresh: () => void} ) => {
  const {t} = useTranslation();
  return <Button className="refresh-button common-btn ml-1" color="primary" variant="text" startIcon={ <RefreshIcon /> }
    onClick={ props.handleRefresh }
  >
    {t( 'labels.refresh' )}
  </Button>
}