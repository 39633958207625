import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ProductApi } from '../../api/ProductApi'
import { IAppState } from '../../../types'
import { getPaginatedData } from '../../services/DataHelperFunctions'
import { IReadProducts } from '../../../types/IRequestTypes'

const initialState = {
  productModels: {
    data: null,
    page: 1,
    totalCount: 0,
    limit: 15,
    searchKey: ''
  },
  refreshProductList:false
}

export const getProductModels = createAsyncThunk( 'app/getProductModels', async ( action: IReadProducts ) => {
  return await ProductApi.getProductModels( action );
} )


const AppSlice = createSlice( {
  name: 'app',
  initialState,
  reducers: {
    setRefreshProductList( state,action ) {
      state.refreshProductList = action.payload;
    }
  },
  extraReducers: ( builder ) => {
    builder.addCase( getProductModels.fulfilled, ( state, action ) => {
      if ( !action.payload.error ) {
        state.productModels = getPaginatedData( state.productModels, action.payload )
      } else {
        state.productModels = initialState.productModels;
      }
    } )
  }
} )

export const {setRefreshProductList} = AppSlice.actions;

export const appSettings = ( state: IAppState ) => state

export default AppSlice.reducer