import React, { useEffect, useState } from 'react'
import { Breadcrumbs, IconButton, Tooltip } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ChevronRight } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@mui/icons-material/Home';
import { useDispatch, useSelector } from 'react-redux';
import { IProduct } from '../../types/IComponentTypes';
import { resetIsDataUpdated, resetProductData, setResetDataFlag, setWorkItem, setEditMode, setProductStatus } from '../store/states/ProcosSlice';
import { AppDispatch, AppState } from '../store/AppStore';
import { DefaultWIValue, ECommonDialogType, EPageNames, FeatureStatus } from '../data/Constants';
import { resetToInitial } from '../store/states/PdmSlice';
import ConfirmationDialog from './ConfirmationDialog';
import { IDialogFor } from '../../types';
import { resetToInitialState } from '../store/states/CategorySlice';


//Home button method props type
interface IHomeButton {
  navigateToHome: () => void;
}


//return the current breadcrum list to to Breadcrum component
const getBreadCrumbs = ( product: IProduct, location, t ) => {
  const breadCrumbs: string[] = []

  if ( product?.name && location.pathname !== EPageNames.Home ) {
    breadCrumbs.push( `${product.name} (${product.id})` );
  } else {
    breadCrumbs.push( t( 'home.header' ) );
  }

  return breadCrumbs;
}

//Returns the Home Icon to the Breadcrumbar component
const HomeButton = ( { navigateToHome }: IHomeButton ) => {

  return <span>
    <IconButton
      onClick={ () => navigateToHome() }
      className="home-icon"
      data-testid="btn-home-icon"
    >
      <HomeIcon />
    </IconButton>
  </span>
}


/**
 * This component is to show the home icon and selected product and feature information.
 * @returns {JSX.Element}  Which renders the Home Icon and Product name and code 
 */
const BreadcrumbBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const [confirmWarning, setConfirmWarning] = useState( { open: false, title: 'dialogBox.unsavedChanges', description: 'messages.cancel.homeWarning', alertFor: ECommonDialogType.HomeClickWarning } );


  const product = useSelector( ( state: AppState ) => state.procos.product );
  const isDataUpdated = useSelector( ( state: AppState ) => {
    if ( state.procos.isDataUpdated || state.pdm.isModified || state.category.isCategoryDataModified ) {
      return true;
    } else {
      return false;
    }
  } );
  const isResetData = useSelector( ( state: AppState ) => state.procos.isResetData )
  const newWorkItem = useSelector( ( state: AppState ) => state.procos.newWorkItem )

  const [breadcrumbs, setBreadCrumbs] = useState<any>( [] );

  //set breadcrumbs state while loading the page
  useEffect( () => setBreadCrumbs( getBreadCrumbs( product, location, t ) ), [ t, product?.id] );

  //Show warning message while navigate to home without saving data
  const navigateToHome = () => {
    if ( isDataUpdated ) {
      setConfirmWarning( { ...confirmWarning, open: true } )
    } else {
      resetData();
    }

  }

  //Call resetData method after confirm the save warning message
  useEffect( () => {
    if ( isResetData && newWorkItem == null ) {
      dispatch( setResetDataFlag( false ) )
      resetData();
    }
  }, [isResetData] )

  //Reset the data on navigation to home
  const resetData = () => {
    
    if( location.pathname.includes( EPageNames.Category ) ) {
      // Set the category state to the initial state
      dispatch( resetToInitialState() )
    } else if( location.pathname.includes( EPageNames.Pdm ) ) {
      //Pdm state reset
      dispatch( resetToInitial() )
    } else {
      //Procos state reset
      dispatch( setEditMode( { isEdit: false } ) )
      dispatch( setProductStatus( FeatureStatus.Active ) );
      dispatch( resetIsDataUpdated() )
      setBreadCrumbs( [] );
      dispatch( setWorkItem( DefaultWIValue ) );
    }   
    navigate( '/' );
    dispatch( resetProductData() )

  }

  //This method triggers when user clicks on cancel or ok button on confirm dialog
  const onConfirmStatus = ( confirm: IDialogFor ) => {
    setConfirmWarning( { ...confirmWarning, open: false } );
    if ( confirm.status ) {
      dispatch( setResetDataFlag( true ) )
    }
  }

  return <>
    {location.pathname !== EPageNames.Home && <HomeButton navigateToHome={ navigateToHome } />}
    <Breadcrumbs className="breadCrumb root" separator={ <ChevronRight /> }>
      {breadcrumbs?.map( ( item: any, i: number ) =>
        <span id={ item + i } key={ item + i } className="breadcrumbs-li">
          <Tooltip key={ i } title={ item }>
            <Link className="item" to={ item.href } replace={ true } >{`${item}`}</Link>
          </Tooltip>
        </span>
      )}
    </Breadcrumbs>
    {confirmWarning.open && <ConfirmationDialog dialogInfo={ confirmWarning } onConfirmAction={ onConfirmStatus } />}
  </>
}
export default BreadcrumbBar;