import React from 'react';
import { useAuth } from 'oidc-react';
import { Unauthenticated } from './Unauthenticated';
import ProductPortfolio from './ProductPortfolio';

/**
 * Renders the home screen which includes the Header,Footer and Product Model components
 * @returns {JSX.Element} the home screen component
 */
const Home = () => {
  const auth = useAuth();
  const token = auth.userData?.access_token;

  //If the token is null then the user will navigate to the Unauthenticated page
  if ( !token ) {
    return <Unauthenticated />
  }

  return <>
    <div className="p-0 h-100" >
      <ProductPortfolio />
    </div>
  </>
}
export default Home;