import React, { useEffect, useState } from 'react'
import { Box, Drawer, IconButton, PaperProps, Tab, Tabs } from '@mui/material'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { ProcosTabs } from '../../data/Constants';
import { useLocation, useNavigate } from 'react-router';
import CategoryIcon from '@mui/icons-material/Category';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import { t } from 'i18next';


//This method returns the custom style for the drawer compoent
const getSXPaperProps = ( isShowDrawer: boolean ) => {
  return {
    position: isShowDrawer ? 'initial' : 'absolute',
    transition: '.15s',
    top: '105px',
    bottom: '0',
    height: '100%',
    minWidth: '150px',
    width: '150px',
  }
}

//This method return the Icon for the Tabs
function getIcon( index: number, isShowDrawer: boolean ) {
  switch ( index ) {
    case 1:
      return <><CategoryIcon className="tab-icon" />{!isShowDrawer && <h3>P</h3>}</>
    case 2:
      return <><PermDataSettingIcon className="tab-icon" />{!isShowDrawer && <h3>O</h3>}</>
    default:
      return ''
  }
}

/**
 * This component renders the Tabs for Product and Option page
 * @returns {JSX.Element} a  TabDrawer component.
 */
const TabDrawer = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState( 0 )
  const [isShowDrawer, setShowDrawer] = useState( true )

  const isFeaturesPage = window.location.href.indexOf( 'features' ) != -1
  
  //Set the Tab value while navigate from other page
  useEffect( () => {
    if ( !isFeaturesPage ) {
      setActiveTab( 0 );
    } else {
      setActiveTab( 1 );
    }
  }, [location.pathname] )
    
  //Set the active tab and navigate to to the corresponding  page while changing the tab
  const onTabChange = ( _: React.SyntheticEvent<Element, Event>, value: number ) => {
    setActiveTab( value )
    navigate( ProcosTabs[value].path )
  }

  //Show/hide the tab drawer while collapse and expand
  const onToggleDrawer = () => {
    setShowDrawer( !isShowDrawer )
  }

  const paperProps: Partial<PaperProps<'div', unknown>> = {
    sx: getSXPaperProps( isShowDrawer ),
    className: `'w-0 ${isShowDrawer ? 'drawerOpen' : ''} `
  };

  let tabIndex = 0;
  let minitabIndex = 0;

  const tabs = ProcosTabs?.map( s =>
    <Tab key={ `${s.id}-${tabIndex}` } id={ s.id } label={ <span>{t(s.name)}</span> }
      value={ tabIndex++ } className="tab-drawer-tabs" iconPosition="start"
      icon={ getIcon( tabIndex, isShowDrawer ) }
    /> )

  const minitabs = ProcosTabs?.map( s =>
    <Tab key={ `${s.id}-${minitabIndex}` } id={ s.id }
      value={ minitabIndex++ } className="tab-drawer-tabs"
      icon={ getIcon( minitabIndex, isShowDrawer ) }
    />
  )
  return <>
    {!isShowDrawer ? <div id="tab-drawer-mini" className="tabDrawerMini">
      <IconButton id="tabDrawerToggle" onClick={ onToggleDrawer } className="toggleIcon">  <KeyboardDoubleArrowRightIcon data-testid="right-arrow-icon" id="right-arrow-icon" className={ 'pinIcon' } /></IconButton>
      <Tabs value={ activeTab } onChange={ onTabChange } orientation="vertical" variant="scrollable" scrollButtons={ false } visibleScrollbar className="tab-toggle drawer-tab">
        {minitabs}
      </Tabs>

    </div> :
      <Drawer id="tab-drawer" variant="permanent" open PaperProps={ paperProps } className="h-100">
        <Box>
          <IconButton className="float-right" onClick={ onToggleDrawer } >
            <KeyboardDoubleArrowLeftIcon data-testid="left-arrow-icon" id="left-arrow-icon" className={ 'pinIcon' } />
          </IconButton>
        </Box>
        <Tabs className="drawer-tab" value={ activeTab } onChange={ onTabChange } orientation="vertical" variant="scrollable" scrollButtons={ false } visibleScrollbar>
          {tabs}
        </Tabs>
      </Drawer>
    }
  </>
}
export default TabDrawer;
