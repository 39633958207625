import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { appSettings } from '../settings';
import { EEnvironments } from '../data/Constants';

/**
 * Renders the Footer which icludes the environment and version of the application .
 * @returns {JSX.Element} the Footer component
 */
const Footer = () => {
  const color:{[index: string]:string} = {
    DEV  : 'bg-yellow',
    TEST : 'bg-orange',
    ACC : 'bg-green',
    PROD : 'bg-gray'
  };

  const [value, setValue] = React.useState( '' );

  //Get the version value from the versioninfo file
  const getVersion = () => {
    return fetch( '../public/versioninfo.json', { cache: 'no-store' } ).then( ( response ) =>
      response.json()
    );
  };

  useEffect( () => {
    //Returns the version value of application
    getVersion().then( ( data ) => {
      setValue( Array.isArray( data ) ? data[0].Version : data.Version );
    } )
  }, [] );

  
  if( appSettings.Environment === EEnvironments.PROD ) {
    return null;
  }

  return (
    <Grid className={ `footer ${color[appSettings.Environment]}` } >
      <Grid className="env">
        <span >{appSettings.Environment}</span>
      </Grid>
      <span className="env">|</span>
      <Grid className="env">
        <span >{value}</span>
      </Grid>
    </Grid>
  );
}

export default Footer;