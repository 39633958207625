import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState, AppDispatch } from '../store/AppStore';
import { setAuthState } from '../store/states/UserSlice';


/**
 * Renders the  error screen for unauthenticated user login
 * @returns {JSX.Element} the unauthenticated  component
 */
export const Unauthenticated = () => {
  const dispatch = useDispatch<AppDispatch>();

  const {t} = useTranslation();
  const [showMessage,setShowMessage] = useState( false );
  const [timer,setTimer] = useState<NodeJS.Timer>()
  const authState = useSelector( ( state:AppState ) => state.user.authState )

  useEffect( ()=>{
    if( authState !== 'logout' ) {
      showMessage && setShowMessage( false );
      setTimer( setTimeout( ()=>setShowMessage( true ),5000 ) );
    }else{
      !showMessage && setShowMessage( true );
    }
    return ()=>{
      timer && clearTimeout( timer );
      setAuthState && dispatch( setAuthState( 'initial' ) );
    }
  },[authState] )

  return (
    showMessage && <h4>
      {t( 'messages.unauthenticated' )}
    </h4> || null
  );
};