import { Box } from '@mui/material'
import { EPageNames} from '../../data/Constants'
import React, { useState } from 'react'
import { useLocation } from 'react-router'
import { recordLengthPicker } from '../../components/Helpers/DataTableHelper'

//Location is checked and if its PDM Procros page return 100 records or the default value is returned.
const getDefaultValue = ( location ) => {
  if( location.pathname == EPageNames.Procos ) {
    return 100;
  }
  return 15;
}
// RecrodsPerPage is be set for Pdm landing page and Procros Page.
const RecordSelect = ( props ) => {

  const location = useLocation();
  const [recordsPerPage, setRecordsPerPage] = useState( getDefaultValue( location ) );

  // here on HandleRefresh the setProductModels and SetRecordsPerPage are refreshed in PDM Landing page.
  const handleRefresh = () => {
    props.handleRefresh();
  }

  const handleRecordsChange = ( e ) => {
    props.handleRecordsChange( e.target.value )
    setRecordsPerPage( e.target.value );
  }

  return <Box className="recordsChange-conatiner">
    { recordLengthPicker( recordsPerPage, handleRecordsChange, handleRefresh ) }
  </Box>
}

export default RecordSelect;