import React, { useState } from 'react';
import { MenuItem, IconButton, Paper, Popper, MenuList, ClickAwayListener, Box } from '@mui/material';
import { IUserPanelProps } from '../../types';
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import * as Constants from '../data/Constants';
import Footer from './Footer';


/**
 * Renders the user name and a menu to logout
 * @param {IUserPanelProps} props the properties for the user panel component
 * @returns {JSX.Element} the user panel
 */
const UserPanel = ( { user, onLogout } : IUserPanelProps ) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>( null );
  const { t } = useTranslation();
  const anchorRef = React.useRef<HTMLButtonElement>( null );
  const [arrowRef, setArrowRef] = React.useState( null );
  const open = Boolean( anchorElement );

  //Triggers the OIDC logout and  will redirect to the Home page 
  const onInternalLogout = () => {
    onLogout();
    setAnchorElement( null );
  }

  //Open the User Panel If user clicks the User Icon  in the header
  const handleClick = ( event: React.MouseEvent<HTMLElement> ) => {
    setAnchorElement( anchorElement ? null : event.currentTarget );
  };

  //Close the User Panel If user clicks anywhere in the ui
  const handleClose = () => {
    setAnchorElement( null )
  }
  return <>
    <span color="secondary">
      {user}
      <Footer />
    </span>
    <IconButton ref={ anchorRef } onClick={ handleClick } className="userPanelIconButton">
      <AccountCircleIcon className="userProfileIcon" />
    </IconButton>
    <Popper open={ open } anchorEl={ anchorElement } placement="bottom" role="tooltip" className="zindex-100"
      modifiers={ [
        ...Constants.popperModifier,
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef,
          }
        }
      ] }
    >
      <div role="tooltip">
        <Box component="div" id="arrow" data-popper-arrow className="popperStyle arrow" ref={ setArrowRef } />
        <Box >
          <Paper >
            <ClickAwayListener onClickAway={ handleClose }>
              <MenuList
                autoFocusItem={ open }
                id="ViewHandler-menu"
                aria-labelledby="SwitchView-button"
              >
                <MenuItem className="panelLogout" onClick={ onInternalLogout } >{t( 'button.logout' )}</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Box>
      </div>
    </Popper>
  </>;
}

export default UserPanel;