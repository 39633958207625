import React from 'react'
import { FormControl, Autocomplete, TextField } from '@mui/material'
import { IAutocompleteProps } from '../../../types';


/**
 * This component renders Autocomplete component to select values
 * @param {IAutocompleteProps} props AutoComplete Props
 * @returns {JSX.Element} a  Autocomplete component.
 */
const AutocompleteInput = ( props: IAutocompleteProps ) => {

  const handleInputChange = ( newVal ) =>{
    const val = newVal ? JSON.stringify( [newVal.code] ) : null;
    props.code ? props.handleChange( props.code, val ) : props.handleChange( newVal );
  }
  return <FormControl fullWidth>
    <Autocomplete
      id={ props.code ?? 'autocomplete' }
      size={ props.size }
      options={ props.data }
      onChange={ ( _, newVal ) => handleInputChange( newVal ) }
      getOptionLabel={ ( option ) => option.name }
      disableClearable = { props.disableClear }
      value={ props?.value }
      disabled={ props.disabled }
      renderInput={ ( params ) =>
        <TextField
          { ...params }
          label={ props.label }
        /> }
    />
  </FormControl>
}

export default AutocompleteInput;