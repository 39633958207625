import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { UserApi } from '../../api/UserApi'
import { IUserState } from '../../../types'
import { IUserDetails } from '../../../types/IRequestTypes'

const initialState:IUserState = {
  authState: 'initial',
  details: null
}

export const getLoggedInUserDetails = createAsyncThunk( 'user/details', async ( payload:IUserDetails ) => {
  return await UserApi.getLoggedInUserDetails( payload );
} )


const UserSlice = createSlice( {
  name: 'user',
  initialState,
  reducers: {
    setAuthState( state, action ) {
      state.authState = action.payload
    },
  },
  extraReducers: ( builder ) => {
    builder.addCase( getLoggedInUserDetails.fulfilled, ( state, action ) => {
      if ( !action.payload.error ) {
        state.details = {
          emailId: action.payload.data.emailId,
          isPdm: action.payload.data.isPdm,
          isAce: action.payload.data.isAce
        }
      }
                
    } )
  }
} )

export const { setAuthState } = UserSlice.actions

export const userSettings = ( state: IUserState ) => state

export default UserSlice.reducer