import React, { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Draggable } from 'react-beautiful-dnd';
import IconType from './IconType';
import { showAddCategoryDialog, deleteSection } from '../../store/states/CategorySlice';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { filterSectionsWithSection, filterSectionsWitContnet } from '../../services/CategoryHelper';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../store/AppStore';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { 
  ICategoryState, 
  IContent, 
  IDialogFor, 
  IMasterView, 
  ISection } from '../../../types';
import { EConfirmDialog } from '../../data/Constants';

const DraggableListItemButtonComponent = ( { content, index, updateAssigned }: {content: any, index: string | number, updateAssigned: ( section: ISection ) => void} ) => {
  const initailConfirmationState = { open: false, title: 'messages.category.confirm', description: 'messages.category.confirmDelete', alertFor: EConfirmDialog.DeleteSection };
  const [confirmDelete, setConfirmDelete] = useState( initailConfirmationState );
  const dispatch = useDispatch<AppDispatch>();
  const categoryData: ICategoryState = useSelector( ( state: AppState ) => state.category );

  const handleClick = ( section: ISection ) => {
    if ( section.id != undefined && updateAssigned != undefined ) {
      const assigned: ISection = { ...categoryData.assigned };
      if ( section.id == assigned.id ) {
        updateAssigned( assigned );
      } else {
        updateAssigned( section );
      }
    }
  }

  const addSection = () => {
    dispatch( showAddCategoryDialog( { section: content, flag: true } ) );
  }

  const deleteContentOrSection = () => {
    if ( content.id != undefined ) {
      setConfirmDelete( { ...confirmDelete, open: true } );
    } else {
      deleteTheSelectedSectionOrFeature();
    }
  }

  const onConfirmAction = ( confirm: IDialogFor ) => {
    setConfirmDelete( initailConfirmationState );
    if ( confirm.status ) {
      deleteTheSelectedSectionOrFeature();
    }
  }

  const deleteTheSelectedSectionOrFeature = () => {
    let masterViewData: { masterView: IMasterView } | null = null;
    if( content.id ) {
      masterViewData = filterSectionsWithSection( categoryData.masterView, content as ISection );
    } else if( content.code ) {
      masterViewData = filterSectionsWitContnet( categoryData.masterView, content as IContent );
    }
    if( masterViewData ) {
      dispatch( deleteSection( masterViewData.masterView ) );
    }
  }

  const getIconType = ( contentIcon ) => {
    if( contentIcon && contentIcon?.familyType != undefined ) {
      return contentIcon.familyType;
    }
    if( contentIcon && contentIcon?.type != undefined ) {
      return contentIcon?.type;
    }
  }

  return (
    <div className="category-section list-item">
      <Draggable
        key={ index }
        draggableId={ content.code }
        index={ content.code }
        isDragDisabled={ !categoryData.editView }
      >
        {( provided ) => 
          <div className="category-section list-item"
            ref={ provided.innerRef }
            { ...provided.draggableProps }
            { ...provided.dragHandleProps }
            key={ content.code }
          >
            <Tooltip title={ content?.familyType || content?.type } >
              <IconType type={ getIconType( content ) } className="item-padding" />
            </Tooltip>
            <ListItemButton sx={ { marginTop:'12px !important',padding:'0px !important',height:'30px !important',minHeight:'30px !important' } } className="item-padding" onClick={ ( e ) => handleClick( content ) }>
              {content?.id && <ListItemText sx={ {padding: '0px', height: '30px'} } primary={ content?.name } />}
              {content?.code && <ListItemText sx={ {padding: '0px', height: '30px'} } primary={ `${content?.description || content?.name} (${content?.code})` } />}
            </ListItemButton>
         

            {confirmDelete.open && <ConfirmationDialog dialogInfo={ confirmDelete } onConfirmAction={ onConfirmAction } />}
          </ div>
        }
      </Draggable>
      {categoryData.editView && <div className="category-icons">
        {content?.id && !content?.hideAddIcon &&
                    <IconButton onClick={ addSection } aria-label="Add"> <AddIcon /></IconButton>
        }
        {( content?.id || content.type ) &&
                    <IconButton onClick={ deleteContentOrSection } aria-label="Delete"> <DeleteIcon /></IconButton>
        }
      </div>}
    </div>
  )
}

export default React.memo( DraggableListItemButtonComponent );