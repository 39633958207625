import { Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from 'store/AppStore'
import { getListOfValues, getProperties, setIsDataUpdated } from '../../store/states/PdmSlice'
import { ECommonDialogType, EPropertyApplicability, EPropertyGroup } from '../../data/Constants'
import { useTranslation } from 'react-i18next'
import { IProperty, IPropertyAssocProps, IPropertyAssociation } from '../../../types'
import { openDialog } from '../../store/states/DialogSlice'
import { PropertiesSection } from './PropertiesSection'
import { PdmApi } from '../../api/PdmApi'
import { getPropAssocRequest } from '../../services/DataHelperFunctions'
import { RefreshButton } from '../IconTypes'
import { IReadPropAssociations, ISavePropAssociations } from '../../../types/IRequestTypes'

//Filter properties based on applicability
const getFilteredProperties = ( properties: IProperty[], applicability: EPropertyApplicability )=>{
  return properties?.filter( ( prop ) => prop.applicability.includes( applicability ) )
}

/**
 * Returns the Property association Form fields to be updated and saved
 * @param {IPropertyAssocProps} props selected Item
 * @returns {React.JSX.Element} JSX Element
 */
export const PropertyAssociation = ( props: IPropertyAssocProps ) => {

  const dispatch = useDispatch<AppDispatch>();
  const {t} = useTranslation();
  const properties = useSelector( ( state: AppState )=> state.pdm.properties )
  const listOfValues = useSelector( ( state: AppState )=> state.pdm.listOfValues )
  const isModified = useSelector( ( state: AppState )=> state.pdm.isModified )

  const [editMasterProps, setEditMasterProps] = useState<boolean>( false );
  const [editProductProps, setEditProductProps] = useState<boolean>( false );
  const [isMasterUpdated, setIsMasterUpdated] = useState<boolean>( false );
  const [isProductUpdated, setIsProductUpdated] = useState<boolean>( false );
  const [masterPropertyAssoc, setMasterPropertyAssoc ] = useState<{ [key: string]: string | null}>();
  const [wipMasterPropertyAssoc, setWipMasterPropertyAssoc ] = useState<{ [key: string]: string | null}>();
  const [productPropertyAssoc, setProductPropertyAssoc ] = useState<{ [key: string]: string | null}>();
  const [wipProductPropertyAssoc, setWipProductPropertyAssoc ] = useState<{ [key: string]: string | null}>();


  useEffect( ()=>{
    if( properties.length === 0 ) {
      dispatch( getProperties( { page: 0,limit: 0 } ) ); //Get all properties
    }
  }, [] )


  useEffect( ()=>{
    if( properties.length > 0 && listOfValues.length == 0 ) {
      dispatch( getListOfValues() ); //Gets List Of Values
      setInitialData();
    }
  }, [properties] )

  //set Property associations for particular material
  const setPropertyAssoc = async () => {
    const payload:IReadPropAssociations = getPropAssocRequest( props.material )
    payload.includeFeature = false;
    const res = await PdmApi.getPropertyAssociations( payload )
    if( res.data ) {
      const masterPropAssoc: { [key: string]: string | null } = {}
      const productPropAssoc: { [key: string]: string | null } = {}
      res.data[0].properties.forEach( assoc=>{
        if( properties.filter( p => p.code == assoc.code )?.[0]?.applicability.includes( EPropertyApplicability.Master ) ) {
          masterPropAssoc[assoc.code] = assoc.value;
        } else {
          productPropAssoc[assoc.code] = assoc.value;
        }
      } )
      setMasterPropertyAssoc( masterPropAssoc )

      //set Product associations
      setProductPropertyAssoc( productPropAssoc )
    }
  }

  const setInitialData = ()=> {
    //Read Property associations for a Product/Feature/Option
    setPropertyAssoc()
    dispatch( setIsDataUpdated( false ) ) //Selected Item changes, data updated is false
    setEditMasterProps( false ); //Selected Item changes, master Props edit is made false
    setEditProductProps( false ); //Selected Item changes, product Props edit is made false
    setIsMasterUpdated( false ); //Selected Item changes, master Props update is made false
    setIsProductUpdated( false ); //Selected Item changes, product Props update is made false
  }

  useEffect( () => {
    setInitialData() //Sets initial data
  }, [props.material.code] )


  //Handle Form field input change for Master props
  const handleMasterInputChange = ( code: string, value )=>{
    if( !isModified ) {
      dispatch( setIsDataUpdated( true ) )
    }
    setIsMasterUpdated( true );
    //This state is used to display data in UI
    setMasterPropertyAssoc({...masterPropertyAssoc, [code]: value ? value.toString() : ''});
    //This state is send as payload on save
    setWipMasterPropertyAssoc( {...wipMasterPropertyAssoc, [code]: value ? value.toString() : ''} ) ;
  }

  //Handle Form field input change for Product props
  const handleProductInputChange = ( code: string, value )=>{
    if( !isModified ) {
      dispatch( setIsDataUpdated( true ) )
    }
    setIsProductUpdated( true );
    //This state is used to display data in UI
    setProductPropertyAssoc({...productPropertyAssoc, [code]: value ? value.toString() : ''});
    //This state is send as payload on save
    setWipProductPropertyAssoc( {...wipProductPropertyAssoc, [code]: value ? value.toString() : ''} ) ;
  }

  //Handle Save functionality
  const handleSave = async ( propType: EPropertyGroup ) => {
    const request:ISavePropAssociations = getPropAssocRequest( props.material );
    const associations: IPropertyAssociation[] = []
    const propertyAssociation = propType === EPropertyGroup.Master ? wipMasterPropertyAssoc : wipProductPropertyAssoc ;
    if( propertyAssociation ) {
      Object.keys( propertyAssociation ).forEach( ( assoc: string )=>{
        associations.push( { 
          code: assoc,
          value: propertyAssociation?.[assoc]
        } )
      } )
    }
    request.properties = associations;
    //Save associations call
    const res = await PdmApi.savePropertyAssociations( request ); 
    if( res && !res.error ) {
      if( propType === EPropertyGroup.Master ) {
        setEditMasterProps( false );
        setIsMasterUpdated( false );
      } else{
        setEditProductProps( false );
        setIsProductUpdated( false );
      }
    }
  }

  //undo master associations
  const undoMasterAssociations = () => {
    setWipMasterPropertyAssoc( {} );
    if( !isProductUpdated ) { //Set master isDataUpdated to false only when both product and master props are not updated
      dispatch( setIsDataUpdated( false ) )
    }
    setEditMasterProps( false );
    setIsMasterUpdated( false );
  }

  //Undo product associations
  const undoProductAssociations = () => {
    setWipProductPropertyAssoc( {} );
    if( !isMasterUpdated ) { //Set master isDataUpdated to false only when both product and master props are not updated
      dispatch( setIsDataUpdated( false ) )
    }
    setEditProductProps( false );
    setIsProductUpdated( false );
  }

  //When clicked on cancel button
  const handleCancel = ( propType: EPropertyGroup ) => {
    //Master Props cancel button click
    if( propType === EPropertyGroup.Master ) { 
      if( isMasterUpdated ) { //if data is updated, show warning dialog else switch to view mode
        dispatch( openDialog( {show: true, type: ECommonDialogType.UndoPropAssociationWarning, handleClickOk: undoMasterAssociations } ) )
      } else{
        setEditMasterProps( false )
      }
    }else{ //Product Props cancel button click
      if( isProductUpdated ) { //if data is updated, show warning dialog else switch to view mode
        dispatch( openDialog( {show: true, type: ECommonDialogType.UndoPropAssociationWarning, handleClickOk: undoProductAssociations } ) )
      } else{
        setEditProductProps( false )
      }
    }
  }

  const refreshPdm = () => {
    dispatch( getProperties( { page: 0,limit: 0 } ) ).then( ()=>{
      dispatch( getListOfValues() )
    } );
    setInitialData() 
  }

  //When refresh icon is clicked
  const handleRefresh = () => {
    if ( !isModified ) {
      refreshPdm()
    }else{
      dispatch( openDialog( {show: true, type: ECommonDialogType.RefreshPdm, handleClickOk: refreshPdm} ) )
    }
  }


  return <Box className="properties-div">
    <div className="d-flex align-center">
      <h3 className="properties-pane-title" data-testid="properties-title">
        { props.material.label }
      </h3>
      <RefreshButton handleRefresh = { handleRefresh } />
    </div>
    <Grid container spacing={ 1 } className="h-100"> 
      <Grid item xs={ 6 } key="MasterProps" data-testid="master-properties" className="pt-0 h-100">
        <PropertiesSection 
          label = { t( 'labels.masterProperties' ) }
          propertyVisibility = { EPropertyGroup.Master }
          editProperties = { editMasterProps }
          properties = { getFilteredProperties( properties, EPropertyApplicability.Master ) }
          listOfValues = { listOfValues }
          associations = { masterPropertyAssoc }
          isDataUpdated = { isMasterUpdated }
          setEditProperties = { setEditMasterProps }
          handleSave = { handleSave }
          handleCancel = { handleCancel }
          handleInputChange = { handleMasterInputChange }
        />
      </Grid>
      <Grid item xs={ 6 } key="ProductProps" data-testid="product-properties" className="pt-0 h-100">
        <PropertiesSection 
          label = { t( 'labels.productProperties' ) }
          propertyVisibility = { EPropertyGroup.Product }
          editProperties = { editProductProps }
          properties = { getFilteredProperties( properties, props.material.type ) }
          listOfValues = { listOfValues }
          associations = { productPropertyAssoc }
          isDataUpdated = { isProductUpdated }
          setEditProperties = { setEditProductProps }
          handleSave = { handleSave }
          handleCancel = { handleCancel }
          handleInputChange = { handleProductInputChange }
        />
      </Grid>
    </Grid>
  </Box>
}