import React from 'react';
import FolderIcon from '@mui/icons-material/Folder';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CasinoOutlinedIcon from '@mui/icons-material/CasinoOutlined';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import FormatAlignCenterSharpIcon from '@mui/icons-material/FormatAlignCenterSharp';
import AddIcon from '@mui/icons-material/Add';




const IconType = (props: {type: string}) => {
    const {type} = props;
    
    let element;
    if(type == undefined || type == 'section') {
        element= <FolderIcon className='category-icon' />;
    }

    if(type == 'delete') {
        element= <DeleteIcon className='category-icon delete-icon border-icon'/>;
    }

    if(type == 'Numeric') {
        element= <CasinoOutlinedIcon  className='category-icon' />;
    }

    if(type == 'Feature') {
        element= <KeyboardCommandKeyIcon className='category-icon'/>;
    }

    if(type == 'String') {
        element= <FormatAlignCenterSharpIcon className='category-icon'/>;
    }

    if (type == 'collapse') {
        element = <ExpandMoreIcon className='category-icon collapse-icon' /> 
    }

    if(type == 'expanded') {
        element = <ChevronRightIcon className='category-icon collapse-icon' />
    }

    if(type == 'add') {
        element = <AddIcon className='category-icon border-icon' />
    }

    return (
        <>
            {element}
        </>
    );

}

export default IconType;