import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, IconButton, InputAdornment, Popper, TextField, Tooltip } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { EPageNames, popperModifier } from '../data/Constants';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import LanguageSetting from './LanguageSetting';
import { useDispatch, useSelector } from 'react-redux';
import { getProductModels, setRefreshProductList } from '../store/states/AppSlice';
import { AppDispatch, AppState } from '../store/AppStore';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { setEditMode } from '../store/states/ProcosSlice';


//Returns the Search Bar component to search the product models in home page
const ProductSearchBar = ( { location, t, searchProduct, resetSearch, searchValue }: any ) => {
  if ( location.pathname === EPageNames.Home ) {
    return <Tooltip title={ t( 'labels.searchproduct' ) }>
      <Autocomplete
        onInputChange={ ( _e, value, reason ) => {
          resetSearch( value, reason );
        } }
        id="product-search-box"
        freeSolo
        options={ [] }
        className="search-bar"
        data-testid="search-bar"
        value={ searchValue }
        renderInput={ ( params ) => <TextField { ...params } onKeyPress={ ( e ) => searchProduct( e ) }
          className="search-inner-text" placeholder={ t( 'labels.search' ) }
          InputProps={ {
            ...params.InputProps,
            startAdornment: <InputAdornment position="end"> <SearchIcon />
            </InputAdornment>
          } }

          PopperComponent={ CustomPopper }
        /> }
      />
    </Tooltip>
  }
}

//Returns the popper component for  search Bar
const CustomPopper = function ( props: any ) {
  return <Popper { ...props } placement="top" modifiers={ popperModifier } />;
};

//Style Class names for disabled and enabled icons
const getClassName = ( value: boolean ) => {
  return value ? 'action-icon-disabled' : 'action-icon'
}


/**
 * Main component to Search Product Models  and change Languge in all the pages and Action panel Icons.
 * @returns {JSX.Element}  which return the product search bar and language component 
 */
const ActionPanel = () => {

  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const isEditMode = useSelector( ( state: AppState ) => state.procos.isEditMode )
  const refreshProductList = useSelector( ( state: AppState ) => state.app.refreshProductList )
  const isDataUpdated = useSelector( ( state: AppState ) => state.procos.isDataUpdated )

  const [searchValue, setSearchValue] = useState( '' );

  const isProcosPage = location.pathname.includes( EPageNames.Procos )

  //remove search value when refresh the product List
  useEffect( () => {
    if ( refreshProductList ) {
      dispatch( setRefreshProductList( false ) );
      setSearchValue( '' )
    }
  }, [refreshProductList] )

  //remove search value when navigate from home Page to other pages
  useEffect( ()=>{
    if( location.pathname !== EPageNames.Home ) {
      setSearchValue( '' )
    }
  },[location.pathname] )

  //To handle Edit Action
  const handleEditMode = ( value: boolean ) => {
    dispatch( setEditMode( { isEdit: value } ) )
  }


  //get the search product from the API
  const searchProduct = ( e ) => {
    if ( e.code === 'Enter' && e.target.value.length > 2 ) {
      setSearchValue( e.target.value )
      dispatch( getProductModels( { productSearch: e.target.value, page: 1, limit: 15 } ) )
    }
  }

  //clear the search value and reset product list
  const resetSearch = ( value: string, reason: string ) => {
    if ( value == '' && searchValue != '' || value != '' && reason === 'clear' ) {
      setSearchValue( '' );
      dispatch( getProductModels( { page: 1, limit: 15 } ) );
    }
  }

  return <>
    <Box className="rowFlex">
      <ProductSearchBar t={ t } location={ location } resetSearch={ resetSearch } searchProduct={ searchProduct } searchValue={ searchValue } />
      {
        isProcosPage && <>
          <Tooltip title={ t( 'tooltip.edit' ) }>
            <span>
              <IconButton
                className={ getClassName( isEditMode ) }
                onClick={ () => handleEditMode( true ) }
                disabled={ isEditMode }
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={ t( 'tooltip.view' ) }>
            <span>
              <IconButton
                className={ getClassName( !isEditMode || isDataUpdated ) }
                onClick={ () => handleEditMode( false ) }
                disabled={ !isEditMode || isDataUpdated }
              >
                <VisibilityIcon />
              </IconButton>
            </span>
          </Tooltip>
        </>}&emsp;
      <LanguageSetting />
    </Box>

  </>
}

export default ActionPanel;