import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import WarningIcon from '@mui/icons-material/Warning';
import DraggableComponent from './DraggableComponent';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { IDialogFor, IConfirmDialog } from '../../types';

const ConfirmationDialog = ( {dialogInfo, onConfirmAction}: {dialogInfo: IConfirmDialog, onConfirmAction: ( confirmInfo: IDialogFor ) => void } ) => {
  const {t} = useTranslation();
  const [open, setOpen] = useState( false );

  useEffect( () => {
    setOpen( dialogInfo.open );
  }, [] )

  const handleOk = () => {
    onConfirmAction( {alertFor:dialogInfo.alertFor, status:true} );
    setOpen( false );
  }

  const handleClose = () => {
    onConfirmAction( {alertFor:dialogInfo.alertFor, status:false} );
    setOpen( false );
  }

  return (
    <Dialog open={ open } aria-labelledby="draggable-dialog-title" PaperComponent={ DraggableComponent } className="common-dialog-style alert-dialog ">
      <DialogTitle className="header cursor-move" >
        <Grid container alignItems="center">
          <><WarningIcon color="warning"/> &nbsp;</> 
          <span > {t( dialogInfo.title )} </span>
        </Grid> 
      </DialogTitle>
      <DialogContent className="p-15px align-center ">
        { typeof dialogInfo.description === 'string' ? t( dialogInfo.description ) : dialogInfo.description }
      </DialogContent>
      <DialogActions>
        <Button className="text-capitalize" onClick={ handleClose }>{t( 'button.cancel' )}</Button>
        <Button variant="contained" onClick={ handleOk } >{t( 'button.ok' )}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;