
import { Container, Box } from '@mui/material';
import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Header from './Header';
import Home from './Home';
import { useDispatch, useSelector } from 'react-redux';
import AppTabs from './AppTabs';
import { UnsupportedBrowser } from './UnsupportedBrowser';
import CommonDialog from './CommonDialog';
import LoadingScreen from './LoadingScreen';
import { DefaultWIValue } from '../data/Constants';
import { setWorkItem } from '../store/states/ProcosSlice';
import { getLoggedInUserDetails } from '../store/states/UserSlice';
import { AppDispatch, AppState } from 'store/AppStore';
import { useAuth } from 'oidc-react';
import { Unauthenticated } from './Unauthenticated';
import { Unauthorized } from './Unauthorized';
import { IUserDetails } from '../../types';
import Procos from './Procos/Procos';
import Pdm from './Pdm/Pdm';
import { Category } from '../category';
import { FeaturesMarketAssociation } from './Procos/FeaturesMarketAssociation';

//Renders the Middle Content of Application based on Authorized User Details
const getMiddleContent = ( token: string | undefined, userDetails: IUserDetails | null )=>{
  if( !token ) { //Unauthenticated
    return <Route path="*" element= { <Unauthenticated/> }/>
  } else if( !userDetails || Object.keys( userDetails ).length == 0 || !userDetails.emailId || !userDetails.isPdm ) { //If user is not an PDM user, Unauthorized
    return <>
      <Route path="*" element={ <Navigate to="/unauthorized" replace={ true } /> }/>
      <Route path="/unauthorized" element={ <Unauthorized/> } />
    </>
  } else { //Authenticated and Authorized user
    return <>
      <Route path="/signin/oidc" element={ <Navigate to="/" replace={ true } /> } /> 
      <Route path="/signin/oidc-silent" element={ <Navigate to="/" replace={ true } /> } />
      
      <Route path="/" element={ <AppTabs /> } >
        <Route index element={ <Home /> } />
        <Route path="procos" index={ true } element={ <Procos /> } />
        <Route path="procos" element={ <Procos /> } >
          <Route path="features" index={ true } element={ <FeaturesMarketAssociation /> } />
        </Route>
        <Route path="pdm" element={ <Pdm /> } />
        <Route path="categories" element={ <Category /> } />
      </Route>
      <Route path="*" element={ <Navigate to="/" replace={ true } /> }/>
    </>
  }
}
/**
 * Main component to handle the layout at the top level.
 * Sets the different routes.
 * @returns {JSX.Element} a container with the UI components at top level
 */
const AppRouter = () => {
  const dispatch = useDispatch<AppDispatch>();
  const auth = useAuth();
  const token = auth.userData?.access_token;

  const userDetails = useSelector( ( state:AppState )=> state.user.details );

  useEffect( () => {
    dispatch( setWorkItem( DefaultWIValue ) );
  }, [] )

  useEffect( () => {
    if( token ) {
      dispatch( getLoggedInUserDetails( {token: token} ) );
    }
  },[token] )

  return (
    <Container maxWidth={ false } className="appRouter root" >
      <Header />
      <Box className="appRouterContent" >
        <Routes>
          {getMiddleContent( token, userDetails )}
        </Routes>
      </Box>
      <UnsupportedBrowser />
      <CommonDialog />
      <LoadingScreen />
    </Container>
  );
}

export default AppRouter;
