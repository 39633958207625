import ApiUrls from '../services/APIUrl';
import { handleSuccess } from '../services/DataHelperFunctions';
import { appSettings } from '../settings';
import AxiosClient from './AxiosClient';
import {errorHandle, successHandle} from '../services/ErrorHandle';
import { IMasterView } from '../../types';
import { IReadFeatures } from '../../types/IRequestTypes';

export const CategoryApi = {

  //This method returns the List Of Values
  getMaster: ( payload: any ) => {

    return AxiosClient
      .get( `${ApiUrls.readMaster}?search=${payload.productCode}`,
        {
          baseURL: appSettings.DisEndpoint
        } )
      .then( ( response: any ) => {
        return handleSuccess( response );
      } ).catch( ( error: any ) => {
        errorHandle( error );
        return error.response;
      } )
  },

  getFeatureFamilies: ( payload: IReadFeatures ) => {
    return AxiosClient
      .post( ApiUrls.readFamilyFeature, {
        workItemId: payload.workItemNum,
        code: '',
        includeScopeFeatures: payload.includeScopeFeatures,
        productCode: payload.productCode
      } )
      .then( ( response: any ) => {
        return handleSuccess( response );
      } ).catch( ( error: any ) => {
        errorHandle( error );
        return error.response;
      } )
  },

  getCategories: () => {
    return AxiosClient
      .post( ApiUrls.addCategories, {},
        {
          baseURL: appSettings.DisEndpoint
        } )
      .then( ( response: any ) => {
        return handleSuccess( response );
      } ).catch( ( error: any ) => {
        errorHandle( error );
        return error.response;
      } )
  },

  saveMaster: ( payload: IMasterView ) => {
    return AxiosClient
      .post( ApiUrls.saveMaster, payload, {
        baseURL: appSettings.DisEndpoint
      } )
      .then( ( response: any ) => {
         successHandle( response );
         return payload;
      } ).catch( ( error: any ) => {
        return errorHandle( error );
      } )
  },
}
