import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { PdmApi } from '../../api/PdmApi'
import { IFeature, IPdmState, IProperty } from '../../../types';
import { ProcosApi } from '../../api/ProcosAPi';
import { updatePropertiesLov } from '../../services/DataHelperFunctions';
import { IPagination, IReadFeatures } from '../../../types/IRequestTypes';

const initialState: IPdmState = {
  features: [],
  properties: [],
  listOfValues:[],
  isModified: false
}

export const getFeatures = createAsyncThunk( 'pdm/getFeatures', async ( payload: IReadFeatures ) => {
  return await ProcosApi.getFeatures( payload );
} )

export const getProperties = createAsyncThunk( 'pdm/getProperties', async ( payload: IPagination ) => {
  return await PdmApi.getProperties( payload );
} )

export const getListOfValues = createAsyncThunk( 'pdm/getListOfValues', async () => {
  return await PdmApi.getListOfValues( );
} )

const PdmSlice = createSlice( {
  name: 'pdm',
  initialState,
  reducers: {
    setIsDataUpdated( state, action ) {
      state.isModified = action.payload;
    },
    resetToInitial( state ) {
      state.features = initialState.features;
      state.isModified = false;
      state.listOfValues = initialState.listOfValues;
      state.properties = initialState.properties;
    }
  },
  extraReducers: ( builder ) => {
    builder.addCase( getFeatures.fulfilled, ( state, action ) => {
      if ( !action.payload.error ) {
        action.payload.data = action.payload.data.filter( ( feature:IFeature ) =>feature.code !== 'PMP' )
        state.features = action.payload.data;
      }
    } );
    builder.addCase( getProperties.fulfilled, ( state, action ) => {
      if ( !action.payload.error ) {
        action.payload.data.properties = action.payload.data.properties.filter((property:IProperty) => property.origin === 'PDM' )
        const propertiesSorted = action.payload.data.properties.sort( ( a, b ) => a.type.localeCompare( b.type ) )
        state.properties = propertiesSorted;
      }
    } );
    builder.addCase( getListOfValues.fulfilled, ( state, action ) => {
      if ( !action.payload.error ) {
        state.listOfValues = action.payload.data;
        state.properties = updatePropertiesLov( state.properties, action.payload.data )
      }
    } );
  }
} )

export const { setIsDataUpdated, resetToInitial } = PdmSlice.actions


export default PdmSlice.reducer