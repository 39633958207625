
import {
  MenuItem,
  Tooltip,
  IconButton,
  Paper,
  Popper,
  MenuList,
  ClickAwayListener,
  Box
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LanguageIcon from '@mui/icons-material/Language';
import { ILanguage } from '../../types';
import { DefaultLanguage, Languages, popperModifier } from '../data/Constants';


/**
 * Renders the Language Selection dropdown
 * @returns {JSX.Element} the Language Setting Component
 */
const LanguageSetting = () => {
  const { i18n, t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>( null );
  const [arrowRef, setArrowRef] = React.useState( null );
  const [languages, setLanguages] = useState<ILanguage[]>( [] );
  const [currentLanguage, setCurrentLanguage] = useState<ILanguage>();

  const open = Boolean( anchorEl );
  const id = open ? 'scroll-playground' : undefined;


  // Open or close the language dropdown if click on the Language Icon
  const handleClick = ( event: React.MouseEvent<HTMLElement> ) => {
    setAnchorEl( anchorEl ? null : event.currentTarget );
  };


  //Close the Language dropdown
  const handleClose = () => {
    setAnchorEl( null )
  }

  //Set the Language List and default language to the state
  useEffect( () => {
    setLanguages( Languages );
    setCurrentLanguage( DefaultLanguage );
  }, [] )

  //This method triggers when changing the language in the dropdown
  const changeLanguage = async ( language: ILanguage ) => {
    try {
      setCurrentLanguage( language );
      i18n.changeLanguage( language.code.replace( '-', '_' ) );
      setAnchorEl( null )
    } catch ( err ) {
      console.log( err )
    }

  };

  return <>
    <Tooltip title={ t( 'labels.language' ) }>
      <span>
        <IconButton className={ `languageContainer ${anchorEl ? 'activeIcon' : ''}` } onClick={ handleClick } aria-describedby={ id } data-testid="btn-language" >
          <LanguageIcon fontSize="small" />
          {currentLanguage?.code}
          <KeyboardArrowDownIcon />
        </IconButton>
      </span>
    </Tooltip>
    <Popper className="zindex-100" id={ id } open={ open } anchorEl={ anchorEl } placement="bottom" role="tooltip" modifiers={ [
      ...popperModifier,
      {
        name: 'arrow',
        enabled: true,
        options: {
          element: arrowRef,
        }
      }
    ] }
    >
      <div role="tooltip">
        <Box component="div" id="arrow" data-popper-arrow className="popperStyle arrow" ref={ setArrowRef } />
        <Box >
          <Paper >
            <ClickAwayListener onClickAway={ handleClose }>
              <MenuList
                autoFocusItem={ open }
                id="language-menu"
                aria-labelledby="language-button"
                data-testid="menu-language"
              >
                {languages.map( ( value: ILanguage ) =>
                  value.code != currentLanguage?.code &&
                  <MenuItem
                    onClick={ () => changeLanguage( value ) }
                    value={ value.name }
                    key={ value.code }
                    data-testid="userSettings-list"
                  >
                    {value.name}&nbsp;({value.code})
                  </MenuItem>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Box>
      </div>
    </Popper>
  </>
}
export default LanguageSetting;