import { Button } from '@mui/material'
import { EPropertyGroup } from '../data/Constants'
import React from 'react'

export const CustomButton = ( label: string, variant: string, disabled:boolean, type: EPropertyGroup, handleAction: ( type: EPropertyGroup )=> void )=>{
  return <Button 
    variant={ variant } 
    className="p-5px mr-5px text-capitalize common-btn" 
    disabled= { disabled }
    onClick={ ()=> handleAction( type ) }
  >
    {label}
  </Button>
}