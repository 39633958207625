import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import IconType from './IconType';
import DraggableListItemButtonComponent from './DraggableListItemButtonComponent';
import { updateMasterView } from '../../store/states/CategorySlice';
import { getMasterViewWithflag } from '../../services/CategoryHelper';
import { AppDispatch, AppState } from '../../store/AppStore';
import { IMasterView, ISection } from '../../../types';
import { Droppable } from 'react-beautiful-dnd';

const DropSection = ( {section, handleToggle, type}:{section: ISection, handleToggle: any, type: string} ) => {
  return (
    <>
      <Droppable droppableId={ `${section.id}:${type}` } key={ section.id }>
        {( provided, snapshot ) =>
          
          <div key={ section.id } className="category-section" ref={ provided.innerRef }
            style={ {
              color: snapshot.isDraggingOver ? '#4FB740' : '',
              border: snapshot.isDraggingOver ? '2px solid #4FB740' : '',
              height: snapshot.isDraggingOver ? '20px !important; margin-top: 10px;' : '',
            } }
            { ...provided.droppableProps }
          >
            <>
              {section?.sections != undefined && section?.sections.length > 0 &&
              <span className="toglle-icon" onClick={ () => handleToggle( section ) }>
                <IconType key={ section.id } type={ section?.open ? 'collapse' : 'expanded' } />
              </span>
              }   
          
              <DraggableListItemButtonComponent key={ section.id } content={ section } index={ section.id } updateAssigned={ handleToggle } />
            </>   
          </ div>
          
        }
      </Droppable>
    
    </>
  )
}

const CategoryComponent = ( { section, updateAssigned, type }: { section: ISection, updateAssigned: ( section: ISection ) => void, type: string } ) => {
  const masterView: IMasterView = useSelector( ( state: AppState ) => state.category.masterView );
  const selectedSection: ISection = useSelector( ( state: AppState ) => state.category.assigned );
  
  const dispatch = useDispatch<AppDispatch>();
  const handleToggle = ( sectionData?: ISection ) => {
    const masterViewData: IMasterView | null = getMasterViewWithflag( { ...masterView }, section );
    if ( masterViewData != null ) {
      dispatch( updateMasterView( masterViewData ) );
      updateAssigned( section );
    }   
  }

  return (
    <div className={ section?.id == selectedSection?.id ? 'selected-section category-folder' : 'category-folder' }>
      
      <DropSection section={ section } handleToggle={ handleToggle } type={ type }/>
      
      {section?.sections.length > 0 &&
        <Collapse in={ section?.open } timeout="auto" unmountOnExit className="sub-section">
          <List component="div" disablePadding>
            {section?.sections.map( ( subSection: ISection ) => 
              <div key={ subSection.id } className="sub-section">
                <CategoryComponent section={ subSection } updateAssigned={ updateAssigned } type={ type } />
              </div>
            )
            }
          </List>
        </Collapse>
      }           

    </ div>
  )
}

export default React.memo( CategoryComponent );