import { AppStore as store } from '../store/AppStore';
import { ECommonDialogType } from '../data/Constants';
import { openDialog } from '../store/states/DialogSlice';
import { t } from 'i18next';

export const errorHandle = ( error: any ) => {
  if ( error && ( error.response.status == 404 || error.response.status == 500 || error.response.status == 400 ) ) {
    const dialogData = { show: true, type: ECommonDialogType.RequestFailed, data: error.response.data.Message};
    store.dispatch( openDialog( dialogData ) );
  }
}

export const successHandle = ( resSuccess: any ) => {
  let dialogData = { show: true, type: ECommonDialogType.SaveSuccessDynamicMessage, data: t('messages.category.savedSuccessfully')}
  if( resSuccess) {        
    store.dispatch( openDialog( dialogData ) );
  } else {
    dialogData = {...dialogData, type: ECommonDialogType.RequestFailed}
    store.dispatch( openDialog( dialogData ) );
  }
    
}