const ApiUrls = {
  // Categories API Url
  readMaster: '/product/v1/masterview/read',
  readFamilyFeature: '/ace/productmodel/v1/families/read',
  addCategories: '/category/v1/read',
  saveMaster: '/product/v1/masterview/create',

  //PDM API url
  readProperties:'/property/v1/read',
  readPropertyAssociation: '/property/v1/association/read',
  createPropertyAssociation: '/property/v1/association/create',
  readLov: '/lov/v1/read',

  //Procos API url
  readWIs: '/ace/v1/wi/read',
  readMarkets: '/lib/v1/market/read',
  readMarketAssociation: '/ace/productmodel/v1/rules/markets/read',
  createRules: '/ace/productmodel/v1/rules/create',

  //Product API url
  readProductModels: '/ace/v1/productmodel/read',

  //User API url
  readUserDetails: '/user/v1/details'

}

export default ApiUrls;