import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveMarketAssociations, revertMarketAssociations, resetFeatureMA, resetIsDataUpdated } from '../store/states/ProcosSlice';
import { IProcosProduct, IWorkItemOption } from '../../types/IComponentTypes';
import { AppDispatch, AppState, AppStore } from '../store/AppStore';
import { closeDialog, openDialog } from '../store/states/DialogSlice';
import DraggableComponent from './DraggableComponent';
import { DefaultWIValue, ECommonDialogType, ERuleType, FeatureStatus } from '../data/Constants';
import { getFeatureMARules, isEqualAssociations } from '../services/DataHelperFunctions';
import { IRule } from '../../types';
import { Trans, useTranslation } from 'react-i18next';
import WarningIcon from '@mui/icons-material/Warning';
import { t } from 'i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


//return the payload for save Market association api
const getSaveRequest = ( workItem, product, procos ) => {
  const featuresMA = AppStore.getState().procos.features.ma;
  const productMALength = AppStore.getState().procos.product.ma?.updatedCountries.length;
  const features = AppStore.getState().procos.features;

  let rules: IRule[] = [];
  const productRule = getProductRequest( product, procos )
  if ( productRule ) {
    rules.push( productRule );
  }
  //get Updated Feature Market associations in Rule format
  rules = [...rules, ...getFeatureMARules( features, featuresMA, productMALength, procos )]

  return {
    productCode: product.id,
    workItemId: workItem.name === DefaultWIValue.name ? 'master' : workItem.id,
    ruleType: ERuleType.Market,
    marketRules: rules,
    isProductActive: procos.productStatus == FeatureStatus.Active ? true : false
  }
}

//return the product rules  for save Market association api
const getProductRequest = ( product: IProcosProduct ) => {
  if ( !product.ma ) {
    return null;
  }
  if ( product.ma.initialCountries.length == 0 && product.ma.updatedCountries.length > 0 ||
    product.ma.initialCountries.length > 0 &&
    !isEqualAssociations( product.ma.initialCountries, product.ma.updatedCountries ) ) {
    return {
      code: product.id,
      familyType: '',
      countries: product.ma.updatedCountries,
    }
  }

  return null;
}


//get Success Message with Failed Rules and Effectivities
const getSuccessMessage = ( workItem: IWorkItemOption, failedRules: string[], effectivityResult: any ) => {
  return <Box>
          <div className="d-flex"><CheckCircleOutlineIcon color='success' className="icon-font-size"/>
             <Trans i18nKey="messages.submit.success" values={ { workItemName: workItem.name } } components={ { 1: <b /> } } />
          </div>
        {failedRules && failedRules?.length > 0 &&
          <>
            <h4 className="text-left m-0 p-5px ">{t( 'labels.failedRules' )}</h4>
            <Box className="failed-rule d-flex" >{failedRules?.map( ( rule ) => {
              return <Box className="p-5px" key={ rule }>{`${rule}`}</Box>
            } )}
            </Box>
          </>
        }
        {effectivityResult && Object.keys( effectivityResult ).length > 0 &&
          <>
            <div className="text-left m-0 p-5px ">{t( 'messages.submit.failedEffectivities' )}</div>
            <Grid container spacing={ 1 } className="fail-effectivity pt-1">
              {Object.keys( effectivityResult )?.map( ( key ) => {
                return <div className="d-flex" key={ key }><span className="item">{`\u2022 ${key}`}</span></div>
              } )
              }
            </Grid>
          </>
        }
      </Box>
}

//returns the dialog title for diffrent type
const getDialogTitle = ( type: ECommonDialogType ) => {

  if ( type === ECommonDialogType.FeatureChangeWarning
    || type === ECommonDialogType.UndoPropAssociationWarning
    || type === ECommonDialogType.RefreshProcos 
    || type === ECommonDialogType.RefreshPdm ) {
    type = ECommonDialogType.Warning;
  } else if ( type === ECommonDialogType.SaveProcos) {
    type = ECommonDialogType.Save
  }

  switch ( type ) {
    case ECommonDialogType.Save:
      return t( 'dialogBox.save' )
    case ECommonDialogType.Revert:
      return t( 'dialogBox.revert' );
    case ECommonDialogType.Reset:
      return t( 'dialogBox.reset' );
    case ECommonDialogType.SaveFailed:
    case ECommonDialogType.RequestFailed:
      return t( 'dialogBox.error' );
    case ECommonDialogType.Warning:
      return <div className="d-flex"><WarningIcon className="warning-icon" /> &nbsp;
        <div>{t( 'dialogBox.unsavedChanges' )}</div></div>
    case ECommonDialogType.SaveProcosSuccess:
    case ECommonDialogType.SaveSuccessDynamicMessage:
      return t('dialogBox.success')
    default:
      return 'Dialog';
  }
}

//returns the dialog content for different type
const getDialogContent = ( type: string, dialogData: any, workItem: IWorkItemOption, failedRules: string[], effectivityResult: any, t ) => {
  switch ( type ) {
    case ECommonDialogType.SaveProcos:
      return getSaveMessage( workItem );
    case ECommonDialogType.Revert:
      return <Trans i18nKey="messages.submit.revert" values={ { workItemName: workItem.name } } components={ { 1: <b /> } } />
    case ECommonDialogType.Reset:
      if ( dialogData.id ) {
        return t( 'messages.submit.reset' );
      } else {
        return t( 'messages.submit.resetAll' );
      }
    case ECommonDialogType.SaveProcosSuccess:
      return getSuccessMessage( workItem, failedRules, effectivityResult );
    case ECommonDialogType.SaveFailed:
      return  <div className='d-flex'><CancelOutlinedIcon color= "error" className='icon-font-size'/>{t( 'messages.submit.error' )}</div>;
    case ECommonDialogType.FeatureChangeWarning:
      return t( 'messages.cancel.featureChangeWarning' );
    case ECommonDialogType.UndoPropAssociationWarning:
      return t( 'messages.cancel.undoPropAssociationWarning' );
    case ECommonDialogType.RefreshProcos:
    case ECommonDialogType.RefreshPdm:
      return t( 'messages.refresh' );
    case ECommonDialogType.RequestFailed:
      return <div className='d-flex'><CancelOutlinedIcon color= "error" className='icon-font-size' />{dialogData}</div>
    case ECommonDialogType.SaveSuccessDynamicMessage:
      return  <div className="d-flex"><CheckCircleOutlineIcon color ="success" className="icon-font-size"/>{dialogData}</div>

    default:
      return 'Dialog';
  }
}

//return the product rules  for save Market association api
const getDialogActions = ( type, closeDialogWindow, handleClickOk, t ) => {
  switch ( type ) {
    case ECommonDialogType.SaveProcos:
      return getDefaultActions( type, closeDialogWindow, handleClickOk, t );
    case ECommonDialogType.Revert:
      return getDefaultActions( type, closeDialogWindow, handleClickOk, t );
    case ECommonDialogType.Reset:
      return getDefaultActions( type, closeDialogWindow, handleClickOk, t );
    case ECommonDialogType.FeatureChangeWarning:
      return getDefaultActions( type, closeDialogWindow, handleClickOk, t );
    case ECommonDialogType.UndoPropAssociationWarning:
      return getDefaultActions( type, closeDialogWindow, handleClickOk, t );
    case ECommonDialogType.RefreshProcos:
    case ECommonDialogType.RefreshPdm:
      return getDefaultActions( type, closeDialogWindow, handleClickOk, t );
    default:
      return <Button onClick={ () => handleClickOk() } >{t( 'button.ok' )}</Button>
  }
}

//returns button section for dialog for diffrent types
const getDefaultActions = ( type, closeDialogWindow, handleClickOk, t ) => {
  return <><Button className="text-capitalize" onClick={ () => closeDialogWindow() }>{t( 'button.cancel' )}</Button>
    <Button variant="contained" onClick={ () => handleClickOk( type ) } >{t( 'button.ok' )}</Button></>
}

//get the save message
const getSaveMessage = ( workItem: IWorkItemOption ) => {
  if ( workItem.name != DefaultWIValue.name ) {
    return <Trans i18nKey="messages.submit.exist" values={ { workItemName: workItem.name } } components={ { 1: <b /> } } />
  } else {
    return <Trans i18nKey="messages.submit.new" components={ { 1: <b /> } } />
  }
}

/**
 * This component is to show the Common dialog for different warnings and message
 * @returns {JSX.Element}  a CommonDialog component 
 */
const CommonDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const dialog = useSelector( ( state: AppState ) => state.dialog )
  const workItem = useSelector( ( state: AppState ) => state.procos.selectedWorkItem )
  const product = useSelector( ( state: AppState ) => state.procos.product );
  const procos = useSelector( ( state: AppState ) => state.procos );


  //Open Dialog after save the data successfully
  useEffect( () => {
    if ( product.saveData ) {
      dispatch( openDialog( {
        show: true,
        type: ECommonDialogType.SaveProcosSuccess
      } ) )
    }

  }, [product.saveData] )

  //dispatch th closediaglog action to close the dialog box
  const closeDialogWindow = () => {
    dispatch( closeDialog() );
  }

  //triggers the action while click on OK button for different type.
  const handleClickOk = ( type: ECommonDialogType ) => {
    //closes the dialog
    dispatch( closeDialog() );
    //specific action on click of ok
    switch ( type ) {
      case ECommonDialogType.SaveProcos:
        dispatch( saveMarketAssociations( getSaveRequest( workItem, product, procos ) ) );
        break;
      case ECommonDialogType.Revert:
        dispatch( revertMarketAssociations() )
        break;
      case ECommonDialogType.Reset:
        dispatch( resetFeatureMA( { id: dialog.data.id, type: dialog.data.featureType } ) )
        break;
      case ECommonDialogType.FeatureChangeWarning:
        dialog.handleClickOk();
        break;
      case ECommonDialogType.UndoPropAssociationWarning:
        dialog.handleClickOk();
        break;
      case ECommonDialogType.RefreshProcos:
        dialog.handleClickOk();
        dispatch( resetIsDataUpdated() )
        break;
      case ECommonDialogType.RefreshPdm:
        dialog.handleClickOk();
        break;
      default:
        dispatch( closeDialog() );
    }
  }

  return <>
    {dialog.show && <Dialog open={ dialog.show } aria-labelledby="draggable-dialog-title" PaperComponent={ DraggableComponent } className="common-dialog-style alert-dialog ">

      <DialogTitle className="header cursor-move">
        {getDialogTitle( dialog.type, t )}
      </DialogTitle>

      <DialogContent className="p-15px text-left ">
        {getDialogContent( dialog.type, dialog.data, workItem, product?.saveData?.failedRuleList, product?.saveData?.effectivityResult, t )}
      </DialogContent>

      <DialogActions >
        {getDialogActions( dialog.type, closeDialogWindow, handleClickOk, t )}
      </DialogActions>

    </Dialog >}
  </>
}

export default CommonDialog;

