import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const BusySlice = createSlice( {
  name: 'busy',
  initialState,
  reducers: {
    setBusy() {
      return true;
    },
    resetBusy() {
      return false;
    },
  },
} )

export const { setBusy, resetBusy } = BusySlice.actions

export default BusySlice.reducer