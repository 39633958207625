import { IOidcSettings } from '../../types';


//This method returns the oidc settings for oidc authentication
const getAuthSettings = async (): Promise<IOidcSettings> => {
  return fetch( '../../public/oidc.config.json' ).then( ( response ) =>
    response.json()
  );
};

export const authSettings = await getAuthSettings();
