import userReducer from './states/UserSlice';
import errorReducer from './states/ErrorSlice';
import { configureStore } from '@reduxjs/toolkit'
import appReducer from './states/AppSlice';
import procosReducer from './states/ProcosSlice';
import dialogReducer from './states/DialogSlice';
import pdmReducer from './states/PdmSlice';
import categoryReducer from './states/CategorySlice';
import busyReducer from './states/BusySlice';

export const AppStore = configureStore(
  {
    reducer : {
      busy: busyReducer,
      error: errorReducer,
      app: appReducer,
      dialog: dialogReducer,
      procos: procosReducer,
      pdm: pdmReducer,
      category: categoryReducer,
      user: userReducer,
    },
    middleware:( getDefaultMiddleware ) => {
      return getDefaultMiddleware( {
        serializableCheck: false
      } )
    }} )

export type AppDispatch = typeof AppStore.dispatch
export type AppState = ReturnType<typeof AppStore.getState>;