import { Box, Divider, Tab, Tabs} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ECommonDialogType, EPageNames, PdmTabs } from '../data/Constants'
import { useLocation, useNavigate } from 'react-router'
import ActionPanel from './ActionPanel'
import BreadcrumbBar from './BreadcrumbBar'
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../store/AppStore'
import { resetIsDataUpdated } from '../store/states/ProcosSlice'
import { resetToInitialState } from '../store/states/CategorySlice'
import { resetToInitial } from '../store/states/PdmSlice'
import ConfirmationDialog from './ConfirmationDialog'
import { IDialogFor } from '../../types'
import { useTranslation } from 'react-i18next'


/**
 * This component renders the Tab Panel for ProCoS and PDM section
 * @returns {JSX.Element} a  App tabs component.
 */
const AppTabs = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();
  
  const [confirmWarning, setConfirmWarning] = useState( { open: false, title: 'dialogBox.unsavedChanges', description: 'messages.cancel.homeWarning', alertFor: ECommonDialogType.HomeClickWarning } );
  const [activeTab, setActiveTab] = useState<number>( 0 );
  const [tabs, setTabs] = useState<React.JSX.Element[]>([]);

  const isDataUpdated = useSelector( ( state: AppState ) => {
    if ( state.procos.isDataUpdated || state.pdm.isModified || state.category.isCategoryDataModified ) {
      return true;
    } else {
      return false;
    }
  } );
  
  //Show warning message while navigate between tabs
  const handleChangeTab = ( e: React.SyntheticEvent<Element, Event>, newTab: number ) => {
    if ( isDataUpdated ) {
      e.preventDefault();
      if( activeTab !== newTab ) {
        setActiveTab( newTab )
        setConfirmWarning( { ...confirmWarning, open: true } )
      }
    }else if( activeTab !== newTab ) {
      navigate( PdmTabs[newTab].path );
      setActiveTab( newTab )
    }
  }
  
  //Reset the data on navigation between tabs
  const resetData = () => {  
    if( location.pathname.includes( EPageNames.Category ) ) {
      dispatch( resetToInitialState() )
    } else if( location.pathname.includes( EPageNames.Pdm ) ) {
      dispatch( resetToInitial() )
    } else {
      dispatch( resetIsDataUpdated() )
    }   
    navigate( PdmTabs[activeTab].path );
  }
  
  //This method triggers when user clicks on cancel or ok button on confirm dialog
  const onConfirmStatus = ( confirm: IDialogFor ) => {
    setConfirmWarning( { ...confirmWarning, open: false } );
    if ( confirm.status ) {
      resetData();
    } else {
      setActiveTab( PdmTabs.findIndex( tab => location.pathname.includes( tab.path ) ) )
    }
  }
const getTabs = () => {
  let tabIndex = 0;
  const tabs = PdmTabs?.map( s =>
    <Tab key={ `${s.id}-${tabIndex}` } id={ s.id } label={ <span>{t( `${s.name}` )}</span> }
      value={ tabIndex++ } className="pdm-tabs" iconPosition="start"
    /> )

    setTabs(tabs);    
}
  
useEffect(() => {
  getTabs();
}, [location]);

useEffect(() => {
  if(location.pathname == PdmTabs[0].path) {
    setActiveTab( 0 )
  }
}, [location]);

  useEffect(() => {
    getTabs();
  }, [t])
  
  return <>
    <Box className="secondaryHeader">
      <BreadcrumbBar />
      <ActionPanel />
    </Box>
    <Divider className="divider" />
    { location.pathname !== '/' && <Box sx={ { borderBottom: 1, borderColor: 'divider' } }>
      <nav className="navbar procos-tab-panel">
        <Tabs className="pdm-tab" value={ activeTab } onChange={ handleChangeTab } orientation="horizontal" variant="scrollable" scrollButtons={ false } visibleScrollbar>
          {tabs}
        </Tabs>   
      </nav>
    </Box>
    }
    <Outlet/>
    {confirmWarning.open && <ConfirmationDialog dialogInfo={ confirmWarning } onConfirmAction={ onConfirmStatus } />}

  </>
}

export default AppTabs;