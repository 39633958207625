import AutocompleteInput from '../InputComponents/AutocompleteInput';
import { MultiAutocompleteInput } from '../InputComponents/MultiAutocompleteInput';
import { NumericFieldInput } from '../InputComponents/NumericFieldInput';
import { TextFieldInput } from '../InputComponents/TextFieldInput';
import { ToggleSwitchInput } from '../InputComponents/ToggleSwitchInput';
import { EFieldSize, EPropertyType } from '../../data/Constants';
import React from 'react';
import { IFormControlProps } from '../../../types';

//returns form control based on property type
export const DynamicInputControl = ( props: IFormControlProps )=>{
  const {property, propValue, handleInputChange, fieldsEnabled} = props
  switch( property.type ) {
    case EPropertyType.Boolean:
      return <ToggleSwitchInput 
        label = { property.name + ' (' + property.code + ')' }
        disabled = { !fieldsEnabled }
        handleInputChange = { handleInputChange }
        value = { propValue }
        code = { property.code }
        size = { EFieldSize.Medium }
      />  
    case EPropertyType.String:
      return <TextFieldInput
        label={ property.name + ' (' + property.code + ')' }
        handleInputChange = { handleInputChange }
        disabled = { !fieldsEnabled }
        value = { propValue }
        code = { property.code }
        size = { EFieldSize.Small }
      />
    case EPropertyType.Numeric:
      return <NumericFieldInput
        label={ property.name + ' (' + property.code + ')' }
        handleInputChange = { handleInputChange }
        disabled = { !fieldsEnabled }
        value = { propValue }
        code = { property.code }
        size = { EFieldSize.Small }
      />
    case EPropertyType.Enum:
      if( property.selectionType == EPropertyType.Single ) {
        let formattedVal = null;
        if( property.listOfValues && property.listOfValues.length > 0 && propValue ) { //Autocomplete for Properties section
          const val = JSON.parse( propValue ) //Check the default value is array or not
          if( val.length > 0 ) {
            formattedVal = property.listOfValues?.find( x => x.code == val[0] )
          }
        }
        return <AutocompleteInput
          data={ property.listOfValues ? property.listOfValues : [] }
          handleChange={ handleInputChange }
          label={ property.name + ' (' + property.code + ')' }
          disabled = { !fieldsEnabled }
          value = { formattedVal }
          code = { property.code }
          disableClear = { false }
          size = { EFieldSize.Small }
        />
      }else if( property.selectionType == EPropertyType.Multiple ) {
        let formattedVal = null;
        if( property.listOfValues && property.listOfValues.length > 0 && propValue ) { //Autocomplete for Properties section
          const val = JSON.parse( propValue );
          formattedVal = val.map( ( y: string ) => property.listOfValues?.find( x => x.code == y ) )
        }
        return <MultiAutocompleteInput
          data={ property.listOfValues ? property.listOfValues : [] }
          handleChange={ handleInputChange }
          label={ property.name + ' (' + property.code + ')' }
          disabled = { !fieldsEnabled }
          value = { formattedVal }
          code = { property.code }
          disableClear = { false }
          size = { EFieldSize.Small }
        />
      }
      break;
    default: return <></>;
  }
  return <></>
}