import React, { useState } from 'react';
import { DataGrid, GridColDef, GridColumnGroupingModel, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { Pagination, Stack, Box } from '@mui/material';
import { EDataTableType, FeatureStatus, TableField, TableHeaders } from '../../data/Constants';
import { AppState } from '../../store/AppStore';
import { useDispatch, useSelector } from 'react-redux';
import { IMarket, IDataTableProps } from '../../../types';
import { useTranslation } from 'react-i18next';
import { CustomToolbar, createGridColumns, createMarketColumns, getGroupingModel } from '../Helpers/DataTableHelper';
import { getTogglableColumns } from '../../services/DataHelperFunctions';

/**
 * This component renders the Data Table which returns the MA for Features and Options and Products
 * @param props Row data and Table type
 * @returns Market association Table for Features and Options
 */
export const DataTable = ( props: IDataTableProps ) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const procos = useSelector( ( state: AppState ) => state.procos )
  const isEditMode = useSelector( ( state: AppState ) => state.procos.isEditMode );
  const marketAssociations = useSelector( ( state: AppState ) => state.procos[props.tableType].ma );

  const [tableKey, setTableKey] = useState( 0 );

  const commonHeaders = TableHeaders[props.tableType]
  let columns: GridColDef[] = createGridColumns( commonHeaders, marketAssociations, isEditMode, procos, props.tableType, dispatch, t );
  let columnGroupingModel: GridColumnGroupingModel = [];

  //Set the Type Column Visibility to false for Feature Type
  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>( {
      [TableField.type]: false
    } );

  //When it is features table add Market Data as Columns
  if ( props.tableType == EDataTableType.Features ) {
    const productMarkets = useSelector( ( state: AppState ) => state.procos.product.ma?.updatedCountries );
    let markets = useSelector( ( state: AppState ) => state.procos.markets );
    markets = markets.filter( ( market: IMarket ) => productMarkets?.includes( market.code ) );
    const marketColumns = createMarketColumns( markets, marketAssociations, isEditMode, procos, dispatch, t );
    if ( marketColumns ) {
      columns = [...columns, ...marketColumns]
      columnGroupingModel = getGroupingModel( markets )
    }
  }

  //To handle Records Change
  const handleRecordsChange = ( event: React.ChangeEvent<unknown> ) => {
    const records = event.target.value;
    props.handleRecordsChange( records );
    setTableKey( records );
  };

  //To Handle Page Change
  const handlePageChange = ( event: React.ChangeEvent<unknown>, value: number ) => {
    props.handlePageChange( value, props.limit );
    setTableKey( value );
  }

  const renderToolbar = ()=>{
    return CustomToolbar( props, isEditMode, procos, handleRecordsChange )
  }

  return <>
    <Box id="procos-data-table" className={ props.className }>
      {props?.rows && <DataGrid
        onRowSelectionModelChange={ ( e ) => props.handleCheckRow( e ) }
        checkboxSelection={ isEditMode && props.tableType == EDataTableType.Features && procos.productStatus == FeatureStatus.Active }
        rowSelectionModel={ props.rowSelectionModel }
        rowHeight={ 22 }
        columnHeaderHeight={ 30 }
        rows={ props?.rows }
        columns={ columns }
        columnGroupingModel={ columnGroupingModel }
        getRowId={ ( row ) => { //To set key to row
          return row['id']
        } }
        columnVisibilityModel={ columnVisibilityModel }
        onColumnVisibilityModelChange={ ( newModel ) =>
          setColumnVisibilityModel( { ...newModel, 'type': false } )
        }
        hideFooterPagination
        disableRowSelectionOnClick
        disableColumnMenu
        hideFooter
        slots={ 
          {
            toolbar: renderToolbar
          } }
        slotProps={ {
          columnsManagement: {
            getTogglableColumns,
          }
        } }
        key={ tableKey }
      />}

    </Box>
    <Stack spacing={ 2 } className={ props.tableType == EDataTableType.Features ? 'procos-features-pagination' : 'procos-pagination' }>
      <Pagination size="small" id="procos-pagination" color="primary" count={ props.pageCount } page={ props.page } onChange={ handlePageChange } />
    </Stack>
  </>
}