import React, { useCallback, useEffect, useRef, useState } from 'react';
import CardComponent from './components/CardComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFeatureFamilyList, 
  getMasterView, 
  saveMaster,
  editView, 
  showAddCategoryDialog, 
  updateAssignedFeature, 
  updateUnassignedFeature, 
  updateMasterView,
  updateCategoryModify
} from '../store/states/CategorySlice';
import {
  getAssignedAndUnassignedFeatureFromFeatureFamily, 
  getSectionContentsWithName,
  getUnassignedFeatureFamily, 
  filterSectionsWithSection, 
  findSection, 
  omitEmptySection,
  updateSectionAndSubSection, 
  reOrderFeatures,
  updateBreadCrumb,
} from '../services/CategoryHelper';
import BreadCrumbComponent from './components/BreadCrumbComponent';
import AddCategoryComponent from './components/AddCategoryComponent';
import { AppDispatch, AppState } from '../store/AppStore';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { EConfirmDialog, EnumCategories } from '../data/Constants';
import { useTranslation } from 'react-i18next';
import { 
  ICategoryState, 
  IContent, 
  IDialogFor, 
  IDragItem, 
  IDragItemSection, 
  IFeatureFamily, 
  IFindSection, 
  IMasterView, 
  ISection, 
  IProductCode, 
  IProductType } from '../../types';
import ConfirmationDialog from '../components/ConfirmationDialog';

const Category = () => {
  const initailConfirmationState = { open: false, title: 'messages.category.confirm', description: 'messages.category.confirmCleanEmptySection', alertFor: ''};
  let skipCheck: boolean = false;
  const changesPerformed = useRef( false );
  const { t } = useTranslation();
  const [breadcrumb, setBreadCrumb] = useState<ISection>();  
  const [masterView, setMasterView] = useState<IMasterView>();
  const [confirmDelete, setConfirmDelete] = useState( initailConfirmationState );

  const dispatch = useDispatch<AppDispatch>();
  const product = useSelector( ( state: AppState ) => state.procos.product );
  const categoryData: ICategoryState = useSelector( ( state: AppState ) => state.category );  

  // This method call on bredcrumb click
  const navigateTo = ( section: ISection ) => {
    const sectionData: IFindSection = findSection( categoryData.masterView.sections, section.id );
    updateAssigned( sectionData.selectedSection as ISection );
  }

  // This method call when compenent mount to initiate the assigned section and based on the dependency check
  // it's use as a call back to update the current selected section into assigned section
  // It's udpate the breadcrumb based on the selected section
  const updateAssigned = useCallback( async ( sectionData: ISection, featureFamily: Array<IFeatureFamily> = categoryData?.featureFamily ) => {
    const section: ISection = { ...sectionData };
    let assignedFeatureWithName: ISection;
    assignedFeatureWithName = getSectionContentsWithName( section, featureFamily );

    dispatch( updateAssignedFeature( assignedFeatureWithName ) );
    const activeBreadcrumb: ISection = updateBreadCrumb( categoryData.masterView.sections, assignedFeatureWithName );
    setBreadCrumb( activeBreadcrumb );
  }, [categoryData.assigned] );

  // This method call when compenent mount to filter out the assigned feature and unassigned feature based on the dependency check
  // It's set the default value of the section when component mount.
  // It's call updateAssigned
  const getFeatureFamily = useCallback( async () => {
    if( !categoryData.masterView || !categoryData.masterView.sections ) {
      return;
    }
    
    let defaultSection: ISection | undefined;
    const [assignedFeatureFamily, unassignedFearue] = await getUnassignedFeatureFamily( categoryData?.masterView, categoryData?.featureFamily );

    if ( defaultSection == undefined && categoryData?.assigned && categoryData?.assigned.id !== undefined && categoryData?.assigned.id !== '' ) {
      const {updatedSection} = filterSectionsWithSection( categoryData?.masterView, categoryData?.assigned );
      defaultSection = updatedSection;
    } 
    
    if ( defaultSection == undefined && categoryData?.masterView?.sections.length > 0 ) {
      defaultSection = categoryData?.masterView?.sections[0];
    }

    dispatch( updateUnassignedFeature( unassignedFearue ) );
    updateAssigned( defaultSection as ISection, assignedFeatureFamily as IFeatureFamily[] );

  }, [categoryData.masterView, categoryData.assigned, categoryData.featureFamily] );

  // This method called when any feture drag from unassigned to assignd or in category
  // Once the drag data hadle it calls the update masterview data
  const updateOnDrag = async ( result: IContent | IDragItemSection, dropToSection: ISection = categoryData.assigned, destinationIndex: number | undefined = undefined, fromSection?:ISection ) => {
    const { contents } = dropToSection;
    let contentsCopy = [...contents];
    let dropItem = result as IContent;
    const dropResult = result as IDragItemSection;
    if( dropResult?.draggableId ) {
      const dragConent = [{ code: dropResult.draggableId, type: '' }];
      const [dragItem, unassignedFeature] = await getAssignedAndUnassignedFeatureFromFeatureFamily( dragConent, categoryData.unassigned );
      dropItem = { code: dragItem[0].code, type: dragItem[0].familyType, name: dragItem[0].description };
    }

    if ( contents == undefined || contents.length == 0 ) {
      contentsCopy = [dropItem as IContent];
    } else {
      if ( destinationIndex != undefined && destinationIndex >= 0 ) {
        contentsCopy.splice( destinationIndex, 0, dropItem as IContent );
      } else {
        contentsCopy = [...contentsCopy, dropItem as IContent];
      }
    }

    let assignedSections: Array<ISection> = [{ ...dropToSection, contents: contentsCopy }];
    if( dropResult?.draggableId == undefined ) {
      assignedSections = [...assignedSections, fromSection] as ISection[];
    } 

    skipCheck = false;
    dispatch( updateCategoryModify() )
    await updateMasterViewData( assignedSections );
  }
  
  // This method called when a unassigned feature drag to category
  // It's provide the detais to the updateOnDrag to complete the drag
  const daragFeatureFromUnAssignedToCategory = ( result: IContent | IDragItemSection, destination: IDragItem, fromSection?: ISection ) => {
    const masterView = {...categoryData.masterView};
    const droppableSection: IFindSection = findSection( masterView.sections, destination.droppableId );

    if ( droppableSection != undefined ) {
      updateOnDrag( result, droppableSection.selectedSection, undefined, fromSection );
    }
  }

  // This method called when a assigned feature drag to category
  const daragFeatureFromAssignedToCategory = ( source: IDragItem, destination: IDragItem ) => {
    const assignedSection: ISection = {...categoryData.assigned};
    const contents: Array<IContent> = [...assignedSection.contents];
    const featureToMove: IContent = contents.splice( source.index, 1 )[0];
    const updatedAssignedSection: ISection = {...assignedSection, contents: contents};
    daragFeatureFromUnAssignedToCategory( featureToMove, destination, updatedAssignedSection );
  }

  // This method called to update master view when the drag or update is done into assigned section.
  const updateMasterViewData = ( assignedSection?: ISection[] ) => {
    const assignedSections: ISection[] = assignedSection ? assignedSection : [{ ...categoryData?.assigned }];
    const masterView: IMasterView | null = updateSectionAndSubSection( categoryData.masterView, assignedSections, skipCheck );
    if ( masterView != null ) {
      dispatch( updateMasterView( masterView ) );
    }
    return masterView;
  }

  const addSection = useCallback( () => {
    dispatch( showAddCategoryDialog( { section: null, flag: true } ) );
  }, [] )

  // This method used to enable the edit view
  const enableEditView = () => {
    dispatch( editView( true ) );
  }

  // This Method called on save button click, it's call omitEmptySection to remove the empty 
  // category that has added and then it call's the save section or ask for confiramtion
  const saveView = async () => {
    const response = await omitEmptySection( categoryData.masterView );
    setMasterView( response.updatedView as IMasterView );
    if( response.emptySectionFound ) {
      setConfirmDelete( { ...confirmDelete, open: true, alertFor: EConfirmDialog.RemoveEmptySection } )
    } else {
      saveSections( response.updatedView as IMasterView );
    }
  }

  // This method called on dialog ok or cancel click, based on confirmation, it' form the action
  const onConfirmAction = ( confirm: IDialogFor ) => {    
    if ( confirm.status && confirm.alertFor == EConfirmDialog.RemoveEmptySection ) {
      saveSections();
    } else if ( confirm.status && confirm.alertFor == EConfirmDialog.DiscardChanges ) {
      onCancelClearChanges();
    }
    setConfirmDelete( initailConfirmationState );
  }

  // This method call saveMasterView action, which call the API to update the masterview 
  const saveSections = ( masterViewData?: IMasterView ) => {
    const _masterView = masterViewData ? masterViewData : masterView;
    if( _masterView != undefined ) {
      _masterView.productCode = product.id;
      changesPerformed.current = false;
      dispatch( saveMaster( _masterView ) );
    }
  }

  // This method call on canecl button click, it's check if there is any chnages are performed or not
  // If there is any change is it will show popup for the conformation
  const cancelView = () => {
    if( !changesPerformed.current ) {
      onCancelClearChanges();
    } else {
      setConfirmDelete( { ...confirmDelete, title: 'messages.category.unsavedChanges', description: 'messages.category.discardChanges', open: true, alertFor: EConfirmDialog.DiscardChanges } )
    }
  }

  // This will call on cancel click if there is not change are done
  const onCancelClearChanges = () => {
    changesPerformed.current = false;
    getMaster();
    dispatch( editView( false ) );
  }

  // This mehtod call the slice action to fetch the feature family list and master View
  const getData = () => {
    const getFeaturePayload: IProductType = { productCode: product.id, includeScopeFeatures: false, workItemNum: '', page: 0, limit: 0 };
    getMaster();
    dispatch( getFeatureFamilyList( getFeaturePayload ) );
  }

  // This mehtod call the slice action to fetch the master View
  const getMaster = () => {
    const getMasterPayload: IProductCode = { productCode: product.id };
    dispatch( getMasterView( getMasterPayload ) );
  }

  // This is lifecycle hook of the component with zero dependencies, call on first time render of the component
  useEffect( () => {
    changesPerformed.current = false;
    getData();
  }, [] );

  // This is lifecycle hook of the component with dependencies
  // It's call when the depencies changes
  useEffect( () => {
    if( categoryData?.editView ) {
      changesPerformed.current = true;
    }
    getFeatureFamily();
  }, [categoryData.masterView, categoryData.featureFamily] );

  // This method called when andy feature drag is happen
  const handleDragEnd = async ( { source, destination, ...args }: any ) => {
    if ( !destination || !categoryData.assigned ) {
      return;
    }

    if ( source.index == destination.index && source.droppableId == destination.droppableId ) {
      return;
    }

    if ( source.droppableId == EnumCategories.Assigned && destination.droppableId == EnumCategories.Unassigned ) {
      return;
    }

    if ( source.droppableId == EnumCategories.Unassigned && destination.droppableId == EnumCategories.Assigned ) {
      await updateOnDrag( args, categoryData.assigned, destination.index );
    }

    if ( source.droppableId == EnumCategories.Assigned && destination.droppableId == EnumCategories.Assigned ) {
      const reorderedContentInSection = await reOrderFeatures( source, destination, categoryData?.assigned );
      skipCheck = true;
      updateMasterViewData( [reorderedContentInSection] );
    }

    if ( source.droppableId == EnumCategories.Unassigned && destination.droppableId !== EnumCategories.Assigned && destination.droppableId != undefined ) {
      const destinatinId = destination.droppableId.toString().split(':')[0];
      const destinationObj = {...destination, droppableId: destinatinId};
      daragFeatureFromUnAssignedToCategory( args, destinationObj );
    }

    if ( source.droppableId == EnumCategories.Assigned && destination.droppableId !== EnumCategories.Assigned && destination.droppableId != undefined ) {
      const destinatinId = destination.droppableId.toString().split(':')[0];
      const destinationObj = {...destination, droppableId: destinatinId};
      daragFeatureFromAssignedToCategory( source, destinationObj );
    }
  };

  return (
    <div className="category">
      <div className="header-section">
        <div className="section-view">
          <h4>{t( 'labels.categories' )}</h4>
          <div className="edit-view">
            <Button onClick={ enableEditView } disabled={ categoryData?.editView } className="text-capitalize" variant="contained" startIcon={ <EditIcon /> }>{t( 'tooltip.edit' )}</Button>
          </div>
        </div>
        <div className="category-header-section">

          <div className="breacrum-header-section">
            <Button variant="contained" disabled={ !categoryData?.editView } className="text-capitalize add-section-button" endIcon={ <AddIcon /> } onClick={ addSection } aria-label="Add category">
              {t( 'button.add' )}
            </Button>
            {breadcrumb && <BreadCrumbComponent breadcrumb={ breadcrumb } navigateTo={ navigateTo } />}
          </div>

          <div className="unassingned-section">
            {t( 'labels.unassigned' )}
          </div>
        </div>

      </div>
      <DragDropContext onDragEnd={ handleDragEnd }>
        <div className="category-content">

          {/* Let pane start */}
          <section className="left-pane resize-hor">
            <CardComponent categoriesData={ categoryData?.masterView } updateAssigned={ updateAssigned } type={ EnumCategories.Category }/>
          </section>
          {/* Let pane end */}

          {/* middle pane start */}
          <section className={ categoryData?.editView ? 'resize-hor middle-pane' : 'resize-hor middle-pane disabled-view' }>
            {categoryData.assigned?.sections?.length > 0 &&
              <div className="assigned-section">
                <CardComponent categoriesData={ categoryData?.assigned } updateAssigned={ updateAssigned } type={ EnumCategories.Assigned }/>
              </div>
            }

            <Droppable droppableId={ EnumCategories.Assigned }>
              {( provided ) =>
                <div className={ categoryData.assigned?.sections?.length == 0 ? 'regular-view' : '' } ref={ provided.innerRef }
                  { ...provided.droppableProps }
                >
                  <CardComponent categoriesData={ categoryData?.assigned?.contents } updateAssigned={ updateAssigned } type={ EnumCategories.Assigned } />
                </div>
              }
            </Droppable>
          </section>

          {/* middle pane end */}

          {/* right pane start */}
          <Droppable droppableId={ EnumCategories.Unassigned }>
            {( provided ) =>
              
              <section className={ categoryData?.editView ? 'right-pane resize-hor' : 'resize-hor right-pane disabled-view' } ref={ provided.innerRef }
                { ...provided.droppableProps }
              >
                <CardComponent categoriesData={ categoryData?.unassigned } updateAssigned={ updateAssigned } type={EnumCategories.Unassigned}/>
              </section>
              
            }
          </Droppable>

          {/* right pane end */}

        </div>
      </DragDropContext>

      {/* Action section */}
      {categoryData?.editView && <div className="footer-action">
        <div className="actions">

          <Button variant="outlined" className="text-capitalize button cancel-button" onClick={ cancelView } aria-label="Cancel View">
            {t( 'button.cancel' )}
          </Button>

          <Button variant="contained" className="text-capitalize button save-button" disabled={ !changesPerformed.current } onClick={ saveView } aria-label="Save View">
            {t( 'button.save' )}
          </Button>

        </div>
      </div>}
      <AddCategoryComponent open={ categoryData.showAddCategoryDialog } />
      {confirmDelete.open && <ConfirmationDialog dialogInfo={ confirmDelete } onConfirmAction={ onConfirmAction } />}
    </div>
  )

}

export default Category;