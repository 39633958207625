import { TextField } from '@mui/material'
import React from 'react'
import { IInputFieldProps } from '../../../types'

/**
 * This component returns JSX element
 * @param {IInputFieldProps} props Input field Props
 * @returns {React.JSX.Element} Numeric field
 */
export const NumericFieldInput = ( props: IInputFieldProps ) =>{
  //This method will not allow to input -ve numbers
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if(parseFloat(event.target.value) >= 0 || event.target.value === ''){
      props.handleInputChange(props.code, event.target.value);
    }
  }
  return <TextField
    id={ props.code + 'numeric' }
    size={ props.size }
    disabled={ props.disabled }
    fullWidth
    label={ props.label }
    type="number"
    onChange={ ( event ) => handleChange(event) } 
    value = { props.value ? props.value : '' }
  />
}