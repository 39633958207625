import { Icon, FormControl, Checkbox, Tooltip, IconButton, Select, MenuItem, Button, Grid } from '@mui/material'
import {GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, GridValueGetter } from '@mui/x-data-grid'
import { Flags } from '../../assets'
import { EDataTableType, ECellType, FeatureStatus, ExcludeColumnFilters, TableField, FeatureTypeFilters, RecordLength, ECommonDialogType, Status, EPageNames } from '../../data/Constants'
import React from 'react'
import { AppDispatch, AppStore } from '../../store/AppStore'
import { updateFeatureMarkets, updateProductMarkets } from '../../store/states/ProcosSlice'
import { IProcosState, IMarketAssociation, IFeaturesAssociations, IMarket } from '../../../types'
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { openDialog } from '../../store/states/DialogSlice';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

//Dispatches an action to update the market association for the passed country to the passed feature
const updateFeatureMA = ( event: React.ChangeEvent<HTMLInputElement>, code: string, marketCode: string, featureType: string, dispatch: AppDispatch ) => {
  dispatch( updateFeatureMarkets( { id: code, market: marketCode, featureType: featureType, checked: event.target.checked } ) )
}
  
//Dispatches an action to update the market association for the passed country for Product
const handleQuoteChange = ( event: React.ChangeEvent<HTMLInputElement>, marketCode: string, dispatch: AppDispatch ) => {
  dispatch( updateProductMarkets( { market: marketCode, checked: event.target.checked } ) )
}

//Reset Feature Market associations
export const handleResetFeatureMA = ( id: string | null, featureType: string | null, dispatch: AppDispatch ) => {
  dispatch( openDialog( {
    show: true,
    type: ECommonDialogType.Reset,
    data: {
      id: id,
      type: featureType
    }
  } ) )
}
  
//Creates the Table cell for features and Options
export const createCell = ( params, col, data, isEditMode: boolean, procos: IProcosState, type: EDataTableType, dispatch: AppDispatch, t ) => {
  switch ( col.cellType ) {
    case ECellType.Feature:
    case ECellType.Option:
      const index = params.row[col.field]?.indexOf( '.' )
      const code = params.row[col.field]?.substring( 0, index )
      const description = params.row[col.field]?.substring( index + 1 )
      return code && description && <>
        <div className="overflow-hidden overflow-ellipsis textWidth50" title={ description }>{description}</div>
        <div className="overflow-hidden overflow-ellipsis textWidth50" title={ code }>{' (' + code + ')'}</div>
      </>
    case ECellType.Flag:
      return <Icon>
        <img src={ Flags[params.row.code] } />
      </Icon>
    case ECellType.Quote:
      const checked = isMarketChecked( params.row['id'], data, type );
      const viewText = checked ? 'Y' : 'N';
      return !isEditMode || procos.productStatus !== FeatureStatus.Active ? <span>{viewText}</span> :
        <FormControl fullWidth>
          <Checkbox className="p-0" onChange={ ( e ) => handleQuoteChange( e, params.row['id'], dispatch ) } checked={ checked } />
        </FormControl>
    case ECellType.Reset:
      const productMALength = AppStore.getState().procos.product.ma?.updatedCountries.length
      const allCountriesSelected = !data || !data[params.row['id']] || data[params.row['id']].updatedCountries.length == 0 || data[params.row['id']].updatedCountries.length == productMALength ? true : false
      const isDisabled = !isEditMode || procos.productStatus !== FeatureStatus.Active || allCountriesSelected || !params.row['isActive']
      return <Tooltip title={ t( 'tooltip.reset' ) }>
        <span>
          <IconButton
            className={ isDisabled ? 'action-icon-disabled' : 'action-icon' }
            onClick={ () => handleResetFeatureMA( params.row['id'], params.row['type'], dispatch ) }
            disabled={ isDisabled }
          >
            <RestartAltIcon />
          </IconButton>
        </span>
      </Tooltip>
    case ECellType.Status:
      return <div className={ params.row.isActive ? 'status-active' : 'status-inactive' } >{params.row.isActive ? FeatureStatus.Active : FeatureStatus.InActive}</div>
    default:
      return params.row[col.field]
  }
}
  
//Return DataGrid Columns with Table Headers
export const createGridColumns = ( tableHeaders: any[], data: IMarketAssociation, isEditMode: boolean, procos: IProcosState, type: EDataTableType, dispatch: AppDispatch, t ) => {
  return tableHeaders.map( header => {
  
    return {
      field: header.field,
      headerName: header.field === TableField.isActive ? t( 'labels.' + TableField.status ) : t( 'labels.' + header.field ), //To assign our own custom value in dropdown of filter button in datagrid
      sortable: header.sortable,
      width: header.width,
      headerAlign: header.headerAlign ? header.headerAlign : 'center',
      align: header.align ? header.align : 'left',
      filterable: !ExcludeColumnFilters.includes( header.field ) ? true : false,
      type: header.field == TableField.type ? 'singleSelect' : null,
      valueOptions: header.field == TableField.type ? FeatureTypeFilters : null,
      renderCell: ( params: GridValueGetter ) => { //renderCell is used to render custom component or styling
        return createCell( params, header, data, isEditMode, procos, type, dispatch, t )
      }
    }
  } )
}
  
//Checks if Market is associated for that feature/option/product or not
const isMarketChecked = ( marketCode: string, marketAssociation: IMarketAssociation, type: EDataTableType ) => {
  if ( marketAssociation?.updatedCountries?.includes( marketCode ) || type == EDataTableType.Features && marketAssociation?.updatedCountries?.length == 0 ) {
    return true;
  }
  return false;
}
  
//returns the Market columns 
export const createMarketColumns = ( markets, featuresAssociation: IFeaturesAssociations, isEditMode: boolean, procos: IProcosState, dispatch: AppDispatch, t ) => {
  return markets && markets?.map( ( market ) => {
    return {
      field: TableField.rfq + market.code,
      headerName: t( 'labels.quoteSymbol' ), //To assign our own custom value in dropdown of filter button in datagrid
      width: 65,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      renderCell: ( params: GridValueGetter ) => { //renderCell is used to render custom component or styling
        let checked: boolean;
        if ( params.row['isActive'] && ( !featuresAssociation || !featuresAssociation[params.row['id']] ) ) {
          checked = true;
        } else if ( params.row['isActive'] ) {
          checked = isMarketChecked( market.code, featuresAssociation[params.row['id']], EDataTableType.Features )
        }
        const checkboxStyle = checked || !params.row['isActive'] ? 'p-1' : 'checkbox-highlight p-1';
        const viewText = checked ? 'Y' : 'N';
        return !isEditMode || procos.productStatus != FeatureStatus.Active ? <span>{viewText}</span> :
          <FormControl>
            <Checkbox disabled={ !params.row['isActive'] } className={ checkboxStyle } onChange={ ( event ) => updateFeatureMA( event, params.row['id'], market.code, params.row[TableField.type], dispatch ) } checked={ checked } />
          </FormControl>
      }
    }
  } )
}
  
//Records Per Page component
export const recordLengthPicker = ( recordsPerPage: number, handleRecordsChange, handleRefresh? ) => {
  const {t} = useTranslation();
  return <div id="data-table-records" className="d-flex align-center recordPicker">
    {t( 'labels.recordsPerPage' ) + ':'}&emsp;
    <FormControl id="data-table-records" size="small" className="" style={ { width: '5.4rem' } }>
      <Select
        id="data-table-record-select"
        data-testid="data-table-record-select"
        name="recordsPerPage"
        value={ recordsPerPage }
        onChange={ handleRecordsChange }
      >
        {RecordLength.map( range => <MenuItem key={ range } value={ range }>{range}</MenuItem> )}
      </Select>
    </FormControl>
    {location.pathname == EPageNames.Home && <Button className="refresh-button common-btn" color="primary" variant="text" startIcon={ <RefreshIcon /> }
      onClick={ handleRefresh }
    >
      {t( 'Refresh' )}
    </Button>}
  </div>
}
  
//Sets the Grouping Model required for Market Column grouping
export const getGroupingModel = ( markets: IMarket[] | null ) => {
  return markets && markets?.map( ( marketCol ) => {
    return {
      groupId: marketCol.code,
      renderHeaderGroup: () => {
        return <>
          <Icon>
            <img src={ Flags[marketCol.code] } />
          </Icon>
          <span style={ { paddingLeft: '3px' } } title={ marketCol.name }>{marketCol.code}</span></>
      },
      children: [{ field: TableField.rfq + marketCol.code }]
    }
  } )
}

const handleSelectStatus = ( props, event: React.ChangeEvent<unknown> ) => {
  props.handleSelectStatus( event );
}

export const CustomToolbar = ( props, isEditMode, procos, handleRecordsChange ) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  return <GridToolbarContainer>
    <Grid container spacing={ 2 }>
      <Grid item xs={ 9 } className="d-flex align-center">
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        {recordLengthPicker( props.limit, handleRecordsChange )}
        {props.tableType == EDataTableType.Features &&
            <Button className="common-btn" disabled={ !isEditMode } color="primary" variant="text" startIcon={ <RestartAltIcon /> } onClick={ () => {
              handleResetFeatureMA( null, null, dispatch )
            } }
            >
              {t( 'button.resetAll' )}
            </Button>}
        {props.tableType === EDataTableType.Features &&
            <Button className="common-btn" color="primary" variant="contained" disabled={ !isEditMode || props.tableType == EDataTableType.Features && procos.productStatus !== FeatureStatus.Active || !procos.featureStatus ? true : false }
              onClick={ () => props.handleClickActive() }
            >
              {t( 'button.statusButton' )}
            </Button>
        }
        {props.tableType !== EDataTableType.Features &&
        <>
          &emsp;
          <FormControl disabled={ !isEditMode } size="small" className="active-menu">
            <Select
              disabled={ !isEditMode || props.tableType == EDataTableType.Features && procos.productStatus !== FeatureStatus.Active ? true : false }
              id="active-select"
              name="active-select"
              value={ procos.productStatus }
              onChange={ ( e ) => handleSelectStatus( props, e ) }
            >
              {Status.map( s => <MenuItem key={ s } value={ s }>{s}</MenuItem> )}
            </Select>
          </FormControl>
        </>
        }
      </Grid>
    </Grid>
  </GridToolbarContainer>
}