import React from 'react';
import UserPanel from './UserPanel';
import { useAuth } from 'oidc-react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/AppStore';
import { setAuthState } from '../store/states/UserSlice';

/**
 * Renders a button for login and logout functionality
 * @returns {JSX.Element} the user menu component
 */
const HeaderLogin = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const auth = useAuth();
  const isLoggedin = auth && auth.userData;
  const userName = `${auth.userData?.profile.name}`;

  return <div className="d-flex align-center">
    {isLoggedin ? <UserPanel user={ userName } onLogout={ () => {
      setAuthState && dispatch( setAuthState( 'logout' ) );
      auth.signOut();
    } }
    />
      : <Button color="secondary" onClick={ () => {
        setAuthState && dispatch( setAuthState( 'login' ) );
        auth.signIn();
      } }
      >{t( 'button.login' )}</Button>}
  </div>
};

export default HeaderLogin;