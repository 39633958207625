import { FormControlLabel, Switch} from '@mui/material'
import React from 'react'
import { IInputFieldProps } from '../../../types';

/**
 * This component returns JSX element
 * @param {IInputFieldProps} props Input field Props
 * @returns {JSX} Toggle switch field
 */
export const ToggleSwitchInput = ( props: IInputFieldProps )=>{
  const val = props.value?.toString().toLowerCase() === 'true' 

  return <FormControlLabel 
    className="toggle-switch"
    id={ props.code + '-toggle-switch' }
    control={ <Switch className="switch-field" checked={ val } size={ props.size }/> }
    label={ props.label }
    disabled={ props.disabled }
    onChange={ ( _, newVal ) => props.handleInputChange( props.code, newVal.toString() ) }
  />
}