import React, { useEffect, useState } from 'react';
import { Box} from '@mui/material'
import { getFeatures, getMarketAssociations, getMarkets, getWorkItems, setWorkItem, resetProductData, setResetDataFlag, setNewWorkItem, resetIsDataUpdated, setProductStatus } from '../../store/states/ProcosSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState, AppStore } from '../../store/AppStore';
import { DefaultWIValue, ECommonDialogType, EDataTableType, EFieldSize, RecordLength } from '../../data/Constants'
import ButtonSection from './ButtonSection';
import AutocompleteInput from '../InputComponents/AutocompleteInput';
import { FeaturesMarketAssociation } from './FeaturesMarketAssociation';
import { DataTable } from './DataTable';
import { useNavigate } from 'react-router-dom';
import { openDialog } from '../../store/states/DialogSlice';
import { IDialogFor, IWorkItemOption } from '../../../types';
import { Trans, useTranslation } from 'react-i18next';
import TabDrawer from './TabDrawer';
import { createRows } from '../../services/DataHelperFunctions';
import { RefreshButton } from '../../components/IconTypes';
import ConfirmationDialog from '../../components/ConfirmationDialog';

/**
 * This component renders the Work Item Dropdown and Data Table which returns the MA for Products
 * @returns {JSX.Element} a  Procos component.
 */
const Procos = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [rows, setRows] = useState<readonly any[]>( [] )
  const [pageCount, setPageCount] = useState( 1 );
  const [limit, setLimit] = useState( RecordLength[3] );
  const [page, setPage] = useState( 1 );
  const [confirmWiWarning, setConfirmWiWarning] = useState( { open: false, 
    title: 'dialogBox.unsavedChanges', 
    description: <></>, 
    alertFor: ECommonDialogType.HomeClickWarning }
  );

  const procos = useSelector( ( state: AppState ) => state.procos )
  const product = useSelector( ( state: AppState ) => state.procos.product )
  const markets = useSelector( ( state: AppState ) => state.procos.markets );
  const workItem = useSelector( ( state: AppState ) => state.procos.selectedWorkItem )
  const workItems = useSelector( ( state: AppState ) => state.procos.workItems )
  const isDataUpdated = useSelector( ( state: AppState ) => state.procos.isDataUpdated )
  const isResetData = useSelector( ( state: AppState ) => state.procos.isResetData )
  const newWorkItem = useSelector( ( state: AppState ) => state.procos.newWorkItem )

  const isFeaturesPage = window.location.href.indexOf( 'features' ) != -1
  const productCode = AppStore.getState().procos.product.id

   
  //trigger the  API to fetch the markets,markeassociation,workitems response
  useEffect( () => {
    const workItemId = workItem?.id != DefaultWIValue.id ? workItem.id : '';
    if ( product?.id ) {
      dispatch( getMarkets( { page: 1, limit: RecordLength[3], workItemId: workItemId } ) );
      dispatch( getWorkItems() );
      dispatch( getFeatures( { productCode: productCode, workItemNum: workItemId, includeScopeFeatures: false, page: 1, limit: RecordLength[3] } ) );

    } else {
      navigate( '/' )
      dispatch( resetProductData() )
    }
    //Call the get market association rule api after changing the WorkItem
    if ( workItem ) {
      setPage( 1 );
      setLimit( RecordLength[3] );
      dispatch( getMarketAssociations( getMarketRuleRequest( workItem, productCode ) ) );
    }
  }, [workItem] );

  //Once Market data is filled Rows are Created
  useEffect( () => {
    if ( markets.length > 0 ) {
      setPageCount( Math.ceil( markets.length / limit ) );
      setRows( createRows( markets, 1, limit ) );
    } else {
      setRows( [] );
    }
  }, [markets] )

  //Update the rows data whenever updated the product marketAssociations
  useEffect( () => {
    if ( product?.ma != null ) {
      setRows( createRows( markets, page, limit, product.ma.updatedCountries ) );
    }
  }, [product.ma] )

  //Reset the WorkItem after reset all the data
  useEffect( () => {
    if ( isResetData && newWorkItem ) {
      dispatch( setResetDataFlag( false ) );
      refreshWorkItemData( newWorkItem );
    }
  }, [isResetData] )

  //Call the get market associations after saving the data 
  useEffect( () => {
    if ( product.saveData && !product.saveData.name ) {
      dispatch( getMarketAssociations( getMarketRuleRequest( workItem, productCode ) ) );
    }

  }, [product.saveData] ) 

  //set the selected work item while changing the vlaue in workitem select
  const handleChangeWorkItem = ( value: IWorkItemOption ) => {

    if ( !isDataUpdated ) {
      refreshWorkItemData( value );
    } else {
      dispatch( setNewWorkItem( { id: value.id, name: value.name } ) );
      setConfirmWiWarning( { ...confirmWiWarning, open: true, description: getWiWarningMessage( value.name ) } )
    }
  }


  const refreshWorkItemData = ( value: IWorkItemOption ) => {
    dispatch( setNewWorkItem( null ) );
    dispatch( setWorkItem( { id: value.id, name: value.name } ) );
    dispatch( resetIsDataUpdated() )
  }

  //get the request payload for getMarketAsssociation Rule API 
  const getMarketRuleRequest = ( value, productId: string ) => {

    return {
      productCode: productId,
      onlyMarketRules: true,
      workItemId: value && value.name != DefaultWIValue.name ? value.id : ''
    }
  }

  //handle Records Change
  const handleRecordsChange = ( pageLimit: number ) => {
    const totalPages = Math.ceil( markets.length / pageLimit )
    setPageCount( totalPages );
    setLimit( pageLimit )
    setPage( 1 );
    setRows( createRows( markets, 1, pageLimit ) );
  }

  //To handle Page change
  const handlePageChange = ( currentPage: number ) => {
    setRows( createRows( markets, currentPage, limit, product.ma?.updatedCountries ) );
    setPage( currentPage );
  }

  const handleSelectStatus = ( e ) => {
    dispatch( setProductStatus( e.target.value ) );
  }

  const refreshProcos = () => {
    const workItemId = workItem?.id != DefaultWIValue.id ? workItem.id : ''
    dispatch( getWorkItems() );
    dispatch( getMarketAssociations( getMarketRuleRequest( workItem, productCode ) ) );
    dispatch( getFeatures( { productCode: productCode, workItemNum: workItemId, includeScopeFeatures: false, page: 1, limit: RecordLength[3] } ) );  
  }

  //calls on Refresh button click
  const handleRefresh = ()=> {
    if ( !isDataUpdated ) {
      refreshProcos()
    }else{
      dispatch( openDialog( {show: true, type: ECommonDialogType.RefreshProcos, handleClickOk: refreshProcos} ) )
    }
  }

  //This method triggers when user clicks on cancel or ok button on confirm dialog
  const onConfirmWiStatus = ( confirm: IDialogFor ) => {
    setConfirmWiWarning( { ...confirmWiWarning, open: false } );
    if ( confirm.status ) {
      dispatch( setResetDataFlag( true ) )
    } else{
      dispatch( setNewWorkItem( null ) );
    }
  }

  //Returns the work item warning message with name
  const getWiWarningMessage = ( workItemName:string ) =>{
    return <Trans i18nKey="messages.cancel.workitemWarning" values={ { workItemName: workItemName } } components={ { 1: <b /> } } />
  }


  return <>
    <Box className="d-flex h-inherit">
      <TabDrawer />
      <Box id="procos-main" className="h-inherit middle-container">
        <div className="w-100 d-flex justify-spacebetween mb-05em">
          <div className="autocomplete-div d-flex">
            <AutocompleteInput
              data={ workItems }
              handleChange={ handleChangeWorkItem }
              value={ workItem }
              label={ t( 'labels.workItem' ) }
              disableClear={ true }
              size = { EFieldSize.Small }
            />
            <RefreshButton handleRefresh = { handleRefresh } />
          </div>
          <ButtonSection />
        </div>
        {!isFeaturesPage &&
      <DataTable
        className="data-table"
        tableType={ EDataTableType.Product }
        pageCount={ pageCount }
        page={ page }
        limit={ limit }
        handlePageChange={ handlePageChange }
        handleRecordsChange={ handleRecordsChange }
        handleSelectStatus={ handleSelectStatus }
        rows={ rows }
      />}
        {isFeaturesPage && <FeaturesMarketAssociation/>}
      </Box>
    </Box>
    {confirmWiWarning.open && <ConfirmationDialog dialogInfo={ confirmWiWarning } onConfirmAction={ onConfirmWiStatus } />}
  </>
}
export default Procos;