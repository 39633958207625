import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import DraggableListItemButtonComponent from './DraggableListItemButtonComponent';
import CategoryComponent from './CategoryComponent';
import { IContent, ISection } from '../../../types';

const CardComponent = ( { categoriesData, updateAssigned, type }: {categoriesData: any, updateAssigned: ( section: ISection ) => void, type: string} ) => {
  const contents = categoriesData && categoriesData?.length > 0 ? categoriesData : [];

  return (
    <Box sx={ { minWidth: 275 } }>
      <Card variant="outlined" >
        <CardContent>
          <List>
            {/* Looping section view */}
            {categoriesData && categoriesData?.sections && categoriesData?.sections.map( ( section: ISection ) => 
              <div key={ section.id } className="category-section">
                <CategoryComponent key={ section.id } section={ section } updateAssigned={ updateAssigned } type={ type } />
              </ div>
            )}

            {/* Looping feature and family */}
            {contents && contents.length > 0 && contents?.map( ( content: IContent, index: number ) => 
              <DraggableListItemButtonComponent key={ content.code } content={ content } index={ index } updateAssigned={ updateAssigned } />
            )}
          </List>
        </CardContent>
      </Card>
    </Box>
  )
}

export default React.memo( CardComponent );