import AxiosClient from './AxiosClient';
import { handleError, handleSuccess } from '../services/DataHelperFunctions';
import ApiUrls from '../services/APIUrl';
import { IReadFeatures, IReadMarketAssociations, IReadMarkets, ISaveMarketAssociations } from '../../types/IRequestTypes';
import { EApiType } from '../data/Constants';

export const ProcosApi = {

  //This method returns the work item list 
  getWorkItems: () => {
    return AxiosClient
      .get( ApiUrls.readWIs )
      .then( ( response ) => {
        return handleSuccess( response );
      } ).catch( ( err ) => {
        return handleError( err )
      } )
  },

  //This method returns the Market list for the Product model
  getMarkets: ( action:IReadMarkets ) => {
    return AxiosClient
      .post( ApiUrls.readMarkets,
        {
          workItemId: action.workItemId ? action.workItemId : null
        }
      )
      .then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          data: response.data,
          page: action.page,
          limit: action.limit,
          totalRecords: total
        }
      } ).catch( ( err ) => {
        return handleError( err )
      } )
  },

  //This method returns the Market association data  for the Product model
  getMarketAssociations: ( action: IReadMarketAssociations ) => {
    return AxiosClient
      .post( ApiUrls.readMarketAssociation, action )
      .then( ( response ) => {
        return handleSuccess( response );
      } ).catch( ( err ) => {
        return handleError( err )
      } )
  },
  //This method returns the Market association data  for the Product model
  saveMarketAssociations: ( action: ISaveMarketAssociations ) => {
    return AxiosClient
      .post( ApiUrls.createRules, action )
      .then( ( response ) => {
        return handleSuccess( response );
      } ).catch( ( err ) => {
        return handleError( err, EApiType.SaveMarketAssociations )
      } )
  },

  //This method makes an API call to get all Features
  getFeatures: ( action: IReadFeatures ) => {
    return AxiosClient
      .post( ApiUrls.readFamilyFeature, 
        {
          workItemId: action.workItemNum,
          code: '',
          includeScopeFeatures: action.includeScopeFeatures,
          productCode: action.productCode
        }
      )
      .then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          data: response.data,
          page: action.page,
          limit: action.limit,
          totalRecords: total,
        }
      } ).catch( ( err ) => {
        return handleError( err )
      } )
  }
}