import axios, { AxiosError, AxiosResponse } from 'axios';
import { appSettings } from '../settings';
import { SessionStore } from '../services/SessionStore';
import { authSettings } from './OidcSettings';
import { v4 as uuidv4 } from 'uuid';
import { resetBusy, setBusy } from '../store/states/BusySlice';
import { AppState } from '../store/AppStore';
import { EnhancedStore } from '@reduxjs/toolkit';

//This method returns the oidc user token from the session storage
export const getAuthToken = () => {
  const sessionKey = `oidc.user:${authSettings.AuthorityUrl}:${authSettings.ClientId}`
  return SessionStore.get( sessionKey )?.access_token;
}

//Create the axios client with  pdm api url 
const AxiosClient = axios.create( {
  baseURL: appSettings.PdmEndpoint,
  timeout: 60000,
  withCredentials: false,
} );

let store: EnhancedStore<AppState>;

export const injectStore = ( _store: typeof store ) => {
  store = _store
}

//Added  the axios request interceptors with custom headers 
AxiosClient.interceptors.request.use( ( config ) => {
  store.dispatch( setBusy() )
  const token = getAuthToken();
  config.headers['X-CORRELATION-ID'] = uuidv4();
  config.headers['Context'] = appSettings.Context;
  config.headers['Content-Type'] = 'application/json; charset=utf-8';
  config.headers['Accept'] = 'application/json';
  config.headers['X-Requested-With'] = 'CfLM-PHC-PDM';
  if ( appSettings.STAuth ) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
} );

//Added the axios reponse interceptors
AxiosClient.interceptors.response.use(
  ( response: AxiosResponse ) => {
    store.dispatch( resetBusy() )
    return response;
  },
  ( error: AxiosError ) => {
    store.dispatch( resetBusy() )
    return Promise.reject( error );
  }
);


export default AxiosClient;