import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../store/AppStore';
import { setFeatureStatus, updateFeatureStatus, updateToInitialFeatureMA } from '../../store/states/ProcosSlice';
import { EDataTableType, RecordLength } from '../../data/Constants';
import { IFeature } from '../../../types/ITypes';
import { DataTable } from './DataTable';

/***
 * To loop through all features and options and update the feature active/inactive status
 */
const getRows = ( features: IFeature[] | null , page: number, limit: number, featureStatus:{ [key: string]: boolean } ):IFeature[] =>{
  const rows:IFeature[] = []
  const featureData:IFeature[] = JSON.parse( JSON.stringify ( features ) );
  if( featureData && featureData.length > 0 ) {
    let count = 1;
    featureData.forEach( ( row:IFeature ) => {
      if ( count > ( page - 1 ) * limit && count <= page * limit ) {
        row.isActive = featureStatus[row.id] ? true : false
        rows.push( row );
      }
      count++
    } );
  }
  return rows;
}


/**
 * This component renders the Data Table which returns the MA for Features and Options
 * @returns {JSX.Element} a  Features DataTable.
 */
export const FeaturesMarketAssociation = () =>{

  const [rows, setRows] = useState<IFeature[]>( [] )
  const [selectedFeatures, setSelectedFeatures] = useState<IFeature[]>( [] )
  const [limit, setLimit] = useState( RecordLength[3] );
  const [page, setPage] = useState( 1 );
  const [pageCount, setPageCount] = useState( 1 );

  const dispatch = useDispatch<AppDispatch>();

  const dialog = useSelector( ( state:AppState )=> state.dialog )
  const procos = useSelector( ( state:AppState )=> state.procos )
  const featureDetails = useSelector( ( state:AppState )=> ( { data: state.procos.features.data } ) )


  useEffect( ()=>{
    if( featureDetails.data ) {
      setPageCount( Math.ceil( featureDetails.data.length / limit ) ) ;
      setRows( getRows( featureDetails.data, 1, limit, procos.features.status.updated ) );
    }else{
      setRows( [] )
    }
  }, [featureDetails.data] )

  //remove the check box selection when close the dialog
  useEffect( ()=>{
    if( !dialog.show && selectedFeatures.length > 0 ) {
      setSelectedFeatures( [] )
    }

  },[dialog.show] )
  

  //Refresh the rows when click feature status change
  useEffect( ()=>{
    setRows( getRows( featureDetails.data, page,limit, procos.features.status.updated ) )
  },[procos.features.status.updated] )
  
  //To Handle Records Change
  const handleRecordsChange = ( limit:number )=>{
    setLimit( limit );
    setPage( 1 );
    setPageCount( Math.ceil( featureDetails.data.length / limit ) ) ;
    setRows( getRows( featureDetails.data, 1, limit, procos.features.status.updated ) )
  }

  //To handle Page change
  const handlePageChange = ( page:number )=>{
    setPage( page )
    setRows( getRows( featureDetails.data, page,limit, procos.features.status.updated ) )
  }

  //dispatch the feature status to state
  const handleClickActive = ( e ) => {
    dispatch( setFeatureStatus( false ) );

    const activeFeatures: string[] = [];
    const currentFeatureStatus = {...procos.features.status.updated};
    selectedFeatures.forEach( ( feature )=>{
      currentFeatureStatus[feature] = !currentFeatureStatus[feature];
      if( currentFeatureStatus[feature] ) {
        activeFeatures.push( feature );
      }
    } )
    dispatch( updateFeatureStatus( currentFeatureStatus ) );
    setRows( getRows( featureDetails.data, page, limit, currentFeatureStatus ) )
    setSelectedFeatures( [] )
    dispatch( updateToInitialFeatureMA( activeFeatures ) );
  }

  //set selected rows to the local state
  const handleCheckRow = ( e ) => {   
    if( e && e.length != 0 ) {
      dispatch( setFeatureStatus( true ) );//enable to active/inactive button
    } else{
      dispatch( setFeatureStatus( false ) );//disable the active/inactive button
    }
    setSelectedFeatures( e );
  }

  return <DataTable
    className="features-table"
    rows= { rows }
    tableType = { EDataTableType.Features }
    pageCount = { pageCount }
    page = { page }
    limit = { limit }
    handlePageChange = { handlePageChange }
    handleRecordsChange = { handleRecordsChange }
    handleClickActive={ handleClickActive }
    handleCheckRow={ handleCheckRow }
    rowSelectionModel={ selectedFeatures }

  />
}