import { ICategory, IContent, IDragItem, IFeatureFamily, IFindSection, IMasterView, ISection } from "../../types/ITypes";

// Here extracting name or description from assignned feateaure families to set in section content to display on the view
export const getSectionContentsWithName = (section: ISection, assignedFeatureFamilies: Array<IFeatureFamily>) => {
    const { contents }: ISection = section;
    if (contents == undefined || contents.length == 0) return section;

    const updatedSectionWithContents: Array<IContent> = contents.map((content: IContent) => {
        const featueFamily = assignedFeatureFamilies.find((family: IFeatureFamily) => content.code == family.code);
        if (featueFamily != undefined) {
            return { ...content, name: featueFamily.description, type: featueFamily.familyType };
        }
        else
            return content;
    })

    section['contents'] = [...updatedSectionWithContents];
    return section;
}

// This will return all the contents form the sections
export const getContents = (masterView: IMasterView) => {
    let updatedContents: Array<IContent> = [];
    const getContent = (sections: ISection[], contents?: Array<IContent>) => {

        if (contents != undefined && contents?.length > 0) {
            updatedContents = [...contents];
        }
        if (sections && sections?.length >= 0) {
            sections.forEach((section: ISection) => {
                if (section.contents != undefined && section.contents.length >= 0) {
                    updatedContents = [...updatedContents, ...section.contents];
                    getContent(section.sections, updatedContents);
                }
            })
        }
    }

    getContent(masterView.sections);
    return updatedContents;
}

// Using this method, filtering the uassinged and assigned feature family,
// it will return the result and featueFamilyfound, result will keep unassigned feature fontFamily and 
// featueFamilyfound will return assigned feature family
export const getUnassignedFeatureFamily = async (masterView: IMasterView, featureFamily: Array<IFeatureFamily>) => {
    let contents: Array<IContent> = [];
    contents = await getContents(masterView);

    if (featureFamily == undefined || featureFamily.length == undefined || featureFamily.length == 0) return [];

    return getAssignedAndUnassignedFeatureFromFeatureFamily(contents, featureFamily);
}

// This method perform the role to filter out the assigned features and unassigned feature, based on contents from feature family
export const getAssignedAndUnassignedFeatureFromFeatureFamily = (contents: Array<IContent>, featureFamily: Array<IFeatureFamily>) => {
    const filteredFeatureFamily: Array<IFeatureFamily> = [];
    const result = featureFamily.filter((md: IFeatureFamily) =>
        contents.every((fd: IContent) => {
            if (fd.code !== md.code) {
                return md;
            } else {
                filteredFeatureFamily.push(md);
            }
        }));
    return [filteredFeatureFamily, result];
}

// This method perform the role to filter out the not available contnet after deletion a content from the assigned section.
export const filterSectionsWitContnet = (masterView: IMasterView, payload: IContent): { masterView: IMasterView, updatedSection: ISection } => {
    let found: boolean = false;
    const { sections } = masterView;
    let contentRemovedFromSection: ISection | undefined = undefined;

        const loopThrough = (sections: Array<ISection>) : Array<ISection> => {
            return sections.map((section: ISection) => {
                const contents = section.contents.filter((content: IContent) => {
                    if (content.code == payload.code) {
                        found = true;
                    }

                    if (content.code !== payload.code) {
                        return content;
                    }
                });
                if (found && contentRemovedFromSection == undefined) {
                    contentRemovedFromSection = { ...section, contents: contents };
                    return { ...section, contents: contents };

                }
                if (!found && section.sections != undefined && section.sections.length > 0) {
                    return {
                        ...section, sections: loopThrough(section.sections)
                    }

                }
                return section;
            });
        }

        const filteredSection = loopThrough(sections);
    

    return { masterView: { ...masterView, sections: filteredSection }, updatedSection: contentRemovedFromSection };
}

// This method perform the role to filter out the not available sections after deletion of a section.
export const filterSectionsWithSection = (masterView: IMasterView, section: ISection): { masterView: IMasterView, updatedSection: ISection } => {
    const { sections } = masterView;
    let filteredSection: Array<ISection> = [];
    let contentRemovedFromSection: ISection | undefined = undefined;

    const filteredData: IFindSection = findSection(sections, section.id);
    if(filteredData) {
        filteredSection = filteredData.filterdSection;
        contentRemovedFromSection = filteredData.selectedSection;
    }

    return { masterView: { ...masterView, sections: filteredSection }, updatedSection: contentRemovedFromSection };
}

// This method perform role to add a falg for expadnding and cloppasing the section having subsections.
export const getMasterViewWithflag = (masterView: IMasterView, selectedSection: ISection): IMasterView | null => {
    if (selectedSection == undefined || selectedSection.id == undefined) return null;
    const { sections }: IMasterView = masterView;
    const updateSection = (sections: ISection[]) => {
        return sections.map((section: ISection) => {            
            if (section?.id == selectedSection?.id && section.sections.length > 0) {
                if(section.sections.length > 0) {
                    const updatedSubSections = section.sections.map((subSection: ISection) => {
                        return {...subSection, hideAddIcon: true};
                    })
    
                    section = {...section, sections: updatedSubSections, open: section?.open ? false : true };
                }
            }
            
            return section;
        });
    }
    const sectionsWithFlag = updateSection(sections);

    return { ...masterView, sections: sectionsWithFlag };
}

// This method perform the role to find the breadcrumb, 
// once it find the selcted section in sections and return the section with active key
export const updateBreadCrumb = (sections: Array<ISection>, selectedSection: ISection) : ISection => {
    // This section filter the section from the sections
    // This approach gives the correct section to show in the breadcrum, even when user selected any nested section
    const sectionToShowInBreadCrumb = sections.filter((section: ISection): ISection | undefined => {
        
        if(section.id === selectedSection.id) return section;
        
        if(section.sections.length > 0) {
            const filteredSection = findSection(section.sections, selectedSection.id);
            if(filteredSection.selectedSection) {
                return section;
            };
        }
    })

    // This section sects the active attribute to tru or false to show as active or inactive
    const loopThroughSection = (subSections: ISection[]) => {
        return subSections?.map((subSection: ISection) : ISection => {
            subSection = {...subSection, active: false };

            if(subSection.id === selectedSection.id) {
                subSection = {...subSection, active: true }; 
            }

            return {
                ...subSection, sections: subSection?.sections.length > 0 ? loopThroughSection(subSection.sections): subSection?.sections
            }
        })
    }

    const _section = sectionToShowInBreadCrumb[0];
    const isRootSelected =  _section && _section.id === selectedSection.id;    
    return {..._section, active: isRootSelected, sections: !isRootSelected ? loopThroughSection(_section?.sections) : _section?.sections};
    
} 

// This method return the filtered category based on the category code
export const findCategoryWithCode = (list: Array<ICategory>, code: string): ICategory => {
    const item: ICategory = list.find((categoryItem: ICategory) => categoryItem.code == code)!;
    return item;
}

// This method compares the two contents and retrun the true or false flag
export const checkConentDifference = (contentsOne: Array<IContent>, contentsSecond: Array<IContent>) => {
    if (contentsOne.length < contentsSecond.length || contentsOne.length > contentsSecond.length) return true;
    if (contentsOne.length == 0 && contentsSecond.length == 0) return false;
    if (contentsOne.length > 0 == contentsSecond.length > 0 && contentsOne.length == contentsSecond.length) {
        if (JSON.stringify(contentsOne) != JSON.stringify(contentsSecond)) {
            return true;
        } else {
            return false;
        }
    }
}

// This method update the sections availbe in master view with the latest assigned sections, and perform when ever the assigned section get's updated.
export const updateSectionAndSubSection = (masterView: IMasterView, assignedSections: ISection[], skipCheck = false): IMasterView | null => {
    let assignedFeatures: ISection;
    let masterViewData = { ...masterView }
    if (masterViewData.sections == undefined || masterViewData.sections.length == 0) return null;

    const updateSectionSubSection = (sections: ISection[]) : ISection[] => {
        return sections && sections.map((section: ISection) => {
            if (section.id == assignedFeatures.id) {
                if (skipCheck) {
                    section = {...sections, ...assignedFeatures};
                }
                const isContentDifferent = checkConentDifference(section.contents, assignedFeatures.contents);
                
                if ( isContentDifferent && !skipCheck) {
                    section = {...section, ...assignedFeatures };
                }           
            }
            return {...section, sections: updateSectionSubSection(section.sections)};
        });
    }

    // this function will update multiple section at once and 
    // will return one master view and in one go changes will get updated
    // this works specially in dragging feature from unassigned to assigned or
    //  assigned to another section
    assignedSections.forEach((section: ISection) => {
        if (section.contents != undefined && section.contents.length > 0) {
            const { contents } = section;
            const cleanedContent = contents.map((content: IContent) => ({ code: content.code, type: content.type }));
            assignedFeatures = { ...section, contents: cleanedContent };
        }

        const sections = updateSectionSubSection(masterViewData.sections);
        masterViewData = {...masterViewData, sections : sections};
    })
    return masterViewData;
}

// This method perform the role to add category or root level or sub category level, 
// it's validate the category is exist or not, if exist return the error message else add the category
export const addCategoryAndSubCategory = (masterView: IMasterView, selectedSection: ISection, newCategoryDetail: {id: string, name: string}) => {
    let isCategoryExist: boolean = false;
    let categoryWithNewCategory: Array<ISection> = [];
    const getName = () => {
       const name = newCategoryDetail?.name.toString().split(':');
       if(name.length > 1) {
        return name[1].trim();
       } else {
        return newCategoryDetail?.name;
       }
    }

    const newCategory: ISection = {
        id: newCategoryDetail?.id,
        name: getName(),
        contents: [],
        sections: [],
        hideAddIcon: false
    }

    const checkIfExist = (sections: Array<ISection>) => {
        return sections.filter((section: ISection) => {
            if (section.name == newCategory.name || section.id == newCategory.id) {
                isCategoryExist = true;
            }
            if (!isCategoryExist) {
                checkIfExist(section.sections);
            }
            return section
        })
    }

    if (masterView && masterView?.sections != undefined && masterView?.sections.length > 0) {
        checkIfExist(masterView.sections);
    }

    if (isCategoryExist) {
        return "messages.category.categoryAlreadyExist";
    }

    if (selectedSection == null || selectedSection == undefined) {
        categoryWithNewCategory = sortSections([...masterView.sections, newCategory]);
    } else {
        const addSectionInSubSection = (sections: ISection[]) => {
            const updateSections = sections.map((subSection: ISection) => {
                if (subSection.id == selectedSection.id) {
                    const updatedSection = sortSections([...subSection.sections, {...newCategory, hideAddIcon: true}]);
                    subSection = { ...subSection, sections: updatedSection };
                }

                if (subSection.sections != undefined && subSection.sections.length >= 0) {
                    const newSection: ISection[] = addSectionInSubSection(subSection.sections);
                    const data = { ...subSection, sections: newSection };
                    return data;
                }

                return subSection;
            })
            return updateSections;
        }

        categoryWithNewCategory = addSectionInSubSection(masterView.sections);
    }
    return categoryWithNewCategory;
}

// This method perform the role to reorder the features visible in the assigned section.
export const reOrderFeatures = async (source: IDragItem, destination: IDragItem, assigned: ISection): Promise<ISection> => {
    const selectedSection: ISection = {...assigned};
    let previousItems: Array<IContent> = [...selectedSection.contents];
    const featureToMove: IContent = previousItems.splice(source.index, 1)[0];
    previousItems.splice(destination.index, 0, featureToMove);
    return { ...selectedSection, contents: previousItems };
}

// This method find the seclected siblings from the subSection and return the found section.
export const getActiveBreadCrumb = (breadcrumb: ISection, assigned: ISection) => {
    const predicateCheck = (section: ISection, index: number, sections: ISection[]) => {
        if (sections.length > 1) {
            const isItSibling = sections.find((_section: ISection) => _section.id == assigned.id);
            if (isItSibling && section.id == assigned.id) return section;
        } else {
            return section;
        }
    }
 
    const filteredSection = loopThrough(breadcrumb.sections, predicateCheck);
    return filteredSection;
}

// This method take accepets the sections and filter function and perform the filter on section list and return the filtered section
const loopThrough = (sections: Array<ISection>, predicate: (section: ISection, index: number, sections: ISection[]) => ISection | undefined): Array<ISection> => {
    return sections.filter(predicate).map((section: ISection): ISection => {
        return {
            ...section, sections: loopThrough(section.sections, predicate)
        }
    })
}

// This method perform the role to finding the section from sections 
// based on sectionId and return filtered sections and updated section of sectionId
export const findSection = (sections: ISection[], sectionId: string): IFindSection => {
    let sectionOfSectionIdProvided: ISection | undefined = undefined;     
    
    const predicateCheck = (section: ISection, index: number, sections: ISection[]) => {
        if(section.id !== sectionId){
         return section;
        } else if(section.id === sectionId) {
            sectionOfSectionIdProvided = section;
        }
     }

    const filteredSection = loopThrough(sections, predicateCheck);
    return {filterdSection: filteredSection, selectedSection: sectionOfSectionIdProvided};
}


// This Method perform the role to omit the empty section availbe into the section
export const omitEmptySection = (masterView: IMasterView) => {
    let isEmptySectionFound: boolean = false;
    const checkEmptySection = (section: ISection) => {
        if(section.contents.length == 0 && section.sections.length == 0) {
            isEmptySectionFound = true;
            return;
        } else if(section.contents.length > 0 || (section.sections.length > 0 && removeEmptySections(section.sections))){
            return section;
        }        
    };

    const removeEmptySections = (sections: Array<ISection>): Array<ISection> => {
        return sections.filter(checkEmptySection).map((section: ISection): ISection => {
            return {...section, sections: removeEmptySections(section.sections)};     
        })
    }
    
    const filterEmptySection = (sectionsData: Array<ISection>) => {
        const filteredSections = sectionsData.map((section: ISection) => {
            let _sections: Array<ISection> = [];
            if(section.contents.length > 0 || section.sections.length > 0) {
                _sections = removeEmptySections(section.sections);
            } else {
                isEmptySectionFound = true;
            }
            if(section.contents.length > 0 || (_sections && _sections.length > 0)) {
                section = {...section, sections: _sections};
                return section;
            }
        });

        return  filteredSections.filter(section => section);          
    }
    
    const filterSection = filterEmptySection(masterView.sections);
    return {updatedView: {...masterView, sections: filterSection}, emptySectionFound : isEmptySectionFound};
}

const sortSections = (sections: Array<ISection>): Array<ISection>  => {
    var sortedSections: Array<ISection> = sections.sort((section1: ISection, section2: ISection) => {
        const sectionId1 = section1.id.toString().split('_')[1];
        const sectionId2 = section2.id.toString().split('_')[1];
        if (sectionId1 > sectionId2) {
            return 1;
        }
    
        if (sectionId1 < sectionId2) {
            return -1;
        }
    
        return 0;
    });

    return sortedSections;
}