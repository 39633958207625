import React from 'react';
import '../style/index.less';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthComponent } from './components/AuthComponent';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { theme } from '../style/theme';
import { ThemeProvider } from '@mui/material';
import './i18n';
import { Provider } from 'react-redux';
import { AppStore } from './store/AppStore';
import { injectStore } from './api/AxiosClient';
import { injectStoreHelper } from './services/DataHelperFunctions';

injectStore( AppStore );
injectStoreHelper( AppStore );

const container = document.getElementById( 'root' )!;
const root = createRoot( container );
root.render(
  <BrowserRouter>
    <LocalizationProvider dateAdapter={ AdapterMoment }>
      <ThemeProvider theme={ theme }>
        <Provider store={ AppStore }>
          <AuthComponent />
        </Provider>
      </ThemeProvider>
    </LocalizationProvider>
  </BrowserRouter>
);