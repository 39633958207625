import { Backdrop, Box, CircularProgress } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'store/AppStore';

/**
 * An overlay with a loading circle which is displayed if there is some background task running
 * @param {ILoadingScreenProps} props the properties for the loading screen component
 * @returns {JSX.Element} the overlay with the loading circle component
 */
const LoadingScreen = () => {

  const busy = useSelector( ( state: AppState )=>state.busy )
  return <> 
    { busy && <Backdrop className="zindex-100" open={ busy } >
      <Box sx={ {display:'block',textAlign:'center'} }>
        <CircularProgress/>
      </Box>
    </Backdrop>}
  
  </>
}

export default LoadingScreen;