import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '../../store/states/DialogSlice';
import { AppState } from 'store/AppStore';
import { ECommonDialogType } from '../../data/Constants';

/**
 * This component renders the Save, cancel and manage options buttons 
 * @returns {JSX.Element} a  ButtonSection component.
 */
const ButtonSection = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDataUpdated = useSelector( ( state: AppState )=> state.procos.isDataUpdated )
  const isEditMode = useSelector( ( state: AppState )=> state.procos.isEditMode )


  //if click on the save button ,open the dialog for user confirmation
  const handleClickSave = () => {
    dispatch( openDialog( {
      show: true,
      type: ECommonDialogType.SaveProcos
    } ) )
  }

  //if click on the revert button dispatch the dialog action to open dialog
  const handleClickRevert = () => {
    dispatch( openDialog( { show: true, type: ECommonDialogType.Revert } ) )
  }
    
  return <>
    <div id="procos-button-section" className="align-center d-flex justify-end">
      {isEditMode && <>
        <Button id="save-button" variant="contained" className="text-capitalize common-btn" disabled= { !isDataUpdated } onClick={ () => handleClickSave() }>{t( 'button.save' )}</Button>&emsp;
        <Button id="revert-button" variant="contained" onClick={ () => handleClickRevert() } className="text-capitalize common-btn" disabled= { !isDataUpdated }>{t( 'button.revert' )}</Button>&emsp;</>}
    </div>
  </>

}

export default ButtonSection;