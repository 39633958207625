import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import { IAutocompleteProps } from '../../../types'

/**
 * Renders an input field to handle multiple options selections
 * @param {IAutocompleteProps} props AutoComplete Props
 * @returns {React.JSX.Element} JSX element
 */
export const MultiAutocompleteInput = ( props: IAutocompleteProps ) => {

  const handleInputChange = ( newVal ) => {
    const val = newVal?.length > 0 ? JSON.stringify( newVal.map( x => x.code ) ) : null;
    props.handleChange( props.code, val );
  }
  
  return <Autocomplete
    multiple
    id={ props.code + '-multi-autocomplete' }
    data-testid = "multi-autocomplete"
    size={ props.size }
    options={ props.data }
    onChange={ ( _, newVal ) => handleInputChange( newVal ) }
    getOptionLabel={ ( option ) => option.name }
    filterSelectedOptions
    disabled={ props.disabled }
    value = { props.value ? props.value : [] }
    renderInput={ ( params ) => 
      <TextField
        { ...params }
        label={ props.label }
      />
    }
  />
}