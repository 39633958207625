import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IFeature, IProcosState, IWorkItemOption } from '../../../types'
import { ProcosApi } from '../../api/ProcosAPi'
import { addMarketFeatureMa, createPaginatedFeatures, getFeatureStatus, getPMPPropertyStatus, removeMarketFeatureMa, revertFeaturesMA, setFeatureMA } from '../../services/DataHelperFunctions'
import { DefaultWIValue, FeatureStatus } from '../../data/Constants'
import { IReadFeatures, IReadMarketAssociations, IReadMarkets, ISaveMarketAssociations } from '../../../types/IRequestTypes'

const initialState: IProcosState = {
  workItems: [],
  newWorkItem: null,
  selectedWorkItem: null,
  isEditMode: false,
  isDataUpdated: false,
  isResetData: false,
  markets: [],
  product: {
    name: null,
    id: null,
    ma: null, //ma = Market Associations
  },
  features: {
    data: null,
    ma: {}, //ma = Market Associations,
    status:{}
  },
  saveData: null,
  productStatus:'Active',
  featureStatus:false
}


//call the getWorkItems api and dispatch the response to the state
export const getWorkItems = createAsyncThunk( 'procos/workItems', async ( ) => {
  return await ProcosApi.getWorkItems();
} )

//call the getMarkets api and dispatch the response to the state
export const getMarkets = createAsyncThunk( 'procos/markets', async ( action: IReadMarkets ) => {
  return await ProcosApi.getMarkets( action );
} )

//call the getMarketAssociations api and dispatch the response to the state
export const getMarketAssociations = createAsyncThunk( 'get/marketAssociation', async ( action: IReadMarketAssociations ) => {
  return await ProcosApi.getMarketAssociations( action );
} )

export const getFeatures = createAsyncThunk( 'procos/getFeatures', async ( action: IReadFeatures ) => {
  return await ProcosApi.getFeatures( action );
} )
//call the saveMarketAssociations api and dispatch the response to the state
export const saveMarketAssociations = createAsyncThunk( 'save/savemarketAssociation', async ( action: ISaveMarketAssociations, thunkAPI ) => {
  const response = await ProcosApi.saveMarketAssociations( action );
  return response;
} )

//initialize the action to set the state variables
const ProcosSlice = createSlice( {
  name: 'procos',
  initialState,
  reducers: {
    updateProductMarkets( state, action ) {
      state.isDataUpdated = true;
      if ( action.payload.checked ) { //Checkbox is checked, add it to associations
        if ( state.product.ma ) {
          state.product.ma.updatedCountries.push( action.payload.market )
        } else {
          state.product.ma = {
            initialCountries: [],
            updatedCountries: [action.payload.market],
          }
        }
        //Update Features Market associations to have the market added at Product level
        state.features.ma = addMarketFeatureMa( state.features.ma, action.payload.market )
      } else { //Unchecked checkbox hence remove it from associations
        const index = state.product.ma?.updatedCountries.indexOf( action.payload.market )
        state.product.ma?.updatedCountries.splice( index, 1 );
        state.features.ma = removeMarketFeatureMa( state.features.ma, action.payload.market, state.product.ma?.updatedCountries.length )
                
      }
    },
    updateFeatureMarkets( state,action ) {
      const featureKey = state.features.ma?.[action.payload.id];
      if( !state.isDataUpdated ) {
        state.isDataUpdated = true;
      }
      if( featureKey ) { //When feature is already present in associations, edit it
        if( action.payload.checked ) //checkbox is checked
        {
          state.features.ma[action.payload.id].updatedCountries.push( action.payload.market )

          /*When initial countries length is 0 which represents it was added by user and the selections are
                     same as product maket selections, delete the association as there is no need to add rule to it */
          if( featureKey.initialCountries.length == 0 && state.product.ma?.updatedCountries.length === featureKey.updatedCountries.length ) {
            delete state.features.ma[action.payload.id];
            return;
          }
        } else //checkbox is unchecked
        {
          const index = featureKey.updatedCountries.indexOf( action.payload.market );

          /*When initial countries length is 0 which represents it was added by user and it is the last selection unchecked,
                    delete the association as there is no need to add rule to it */
          if( state.features.ma[action.payload.id].initialCountries.length == 0 && state.features.ma[action.payload.id].updatedCountries.length == 1 ) {
            delete state.features.ma[action.payload.id];
            state.features.status.updated[action.payload.id] = false
            return; 
          }
          state.features.ma[action.payload.id].updatedCountries.splice( index, 1 );
          if( state.features.ma[action.payload.id].updatedCountries.length === 0 ) {
            state.features.status.updated[action.payload.id] = false
          }
          
        }
      } else{ //When feature is not present in associations, Add it
        //since no key is present, its always uncheck case
        const newMarkets = state.product.ma?.updatedCountries.filter( market => market != action.payload.market )
        state.features.ma = { ...state.features.ma, 
          [action.payload.id]: {
            initialCountries: [],
            updatedCountries: newMarkets
          }
        }
        if( newMarkets.length == 0 ) {
          state.features.status.updated[action.payload.id] = false
        }
      }
      //Add Feature Type
      state.features.ma[action.payload.id].type = action.payload.featureType
    },
    resetFeatureMA( state, action ) {
      if( !state.isDataUpdated ) {
        state.isDataUpdated = true;
      }
      if( !action.payload.id )//RESET ALL
      {
        //loop through all ssociations and reset all.
        Object.keys( state.features.ma ).forEach( function( key ) {

          /* Initial countries 0 indicates they are selcted by user in current session and association 
                    can be deleted while reset since there is no need to create a rule as it already follows product level*/
          if( state.features.ma[key].initialCountries.length == 0 ) {
            delete state.features.ma[key];
            return;
          }
          //Update the countries of features to Product Countries
          state.features.ma[key].updatedCountries = [...state.product.ma?.updatedCountries];
        } )
      } else //RESET
      {
        const featureKey = state.features.ma[action.payload.id];
        /* Initial countries 0 indicates they are selcted by user in current session and association 
                    can be deleted while reset since there is no need to create a rule as it already follows product level*/
        if( featureKey.initialCountries.length == 0 ) {
          delete state.features.ma[action.payload.id];
          return;
        }
        //Update the countries of features to Product Countries
        state.features.ma[action.payload.id].type = action.payload.featureType;
        state.features.ma[action.payload.id].updatedCountries = [...state.product.ma?.updatedCountries];
      }
    },
    setEditMode( state, action ) {
      state.isEditMode = action.payload.isEdit
    },
    setProduct( state, action ) {
      state.product.name = action.payload.name
      state.product.id = action.payload.id
    },
    setWorkItem( state, action ) {
      state.selectedWorkItem = action.payload
    },
    revertMarketAssociations( state ) {
      if( state.product.ma != null ) {
        state.product.ma.updatedCountries = state.product.ma != null ? state.product.ma?.initialCountries : [];
      }
      state.productStatus = 'Active';
      state.features.status.updated = state.features.status ? state.features.status?.initial : [];
      state.features.ma = revertFeaturesMA( {...state.features.ma}, state.product.ma?.updatedCountries );
      state.isDataUpdated = false;
      state.featureStatus = false
    },
    resetIsDataUpdated( state ) {
      state.isDataUpdated = initialState.isDataUpdated;
    },
    resetProductData( state ) {
      state.product = initialState.product;
      state.features = initialState.features;
      state.workItems = []
    },
    setResetDataFlag( state, action ) {
      state.isResetData = action.payload
    },
    setNewWorkItem( state, action ) {
      state.newWorkItem = action.payload
    },
    setProductStatus( state, action ) {
      state.productStatus = action.payload,
      state.isDataUpdated = true;
    },
    setFeatureStatus( state, action ) {
      state.featureStatus = action.payload
    },
    updateFeatureStatus( state, action ) {
      state.features.status.updated = action.payload;
      state.isDataUpdated = true;
    },
    updateToInitialFeatureMA( state, action ) {
      if( action.payload ) {
        action.payload.forEach( ( key )=>{
          const featureKey = state.features.ma?.[key];
          if( featureKey ) {
            //state.features.ma?.[key].updatedCountries = state.features.ma?.[key].initialCountries;
            state.features.ma = { ...state.features.ma, 
              [key]: {
                initialCountries: state.features.ma?.[key].initialCountries,
                updatedCountries:  state.features.ma?.[key].updatedCountries
              }
            }
          } else{
            state.features.ma = { ...state.features.ma, 
              [key]: {
                initialCountries: [],
                updatedCountries:  state.product.ma.updatedCountries
              }
            }
          }
        } )
      }
    }

  },
  extraReducers: ( builder ) => {
    builder.addCase( getWorkItems.fulfilled, ( state, action ) => {
      if ( !action.payload.error ) {
        let workItemList: IWorkItemOption[] = [DefaultWIValue]
        workItemList = [...workItemList, ...action.payload.data];
        state.workItems = workItemList
      }
    } );
    builder.addCase( getMarkets.fulfilled, ( state, action ) => {
      if ( !action.payload.error ) {
        state.markets = action.payload.data
      } else {
        state.markets = initialState.markets;
      }
    } );
    builder.addCase( getFeatures.fulfilled, ( state, action ) => {
      if ( !action.payload.error ) {
        state.productStatus = getPMPPropertyStatus( action.payload.data ) ? FeatureStatus.Active : FeatureStatus.InActive;
        action.payload.data = action.payload.data.filter( ( feature:IFeature ) =>feature.code !== 'PMP' );
        const featuresData = createPaginatedFeatures( action.payload );
        state.features.data = featuresData;
        state.features.status.initial = getFeatureStatus( action.payload );
        state.features.status.updated = state.features.status.initial;
      }
    } );
    builder.addCase( getMarketAssociations.fulfilled, ( state, action ) => {
      state.isDataUpdated = false;
      if ( !action.payload.error ) {
        const product = action.payload.data.find( x => x.code == state.product.id );
        // state.product.marketAssociations = product?.countries
        if ( product ) {
          state.product.ma = {
            initialCountries: product.countries,
            updatedCountries: product.countries
          }
        }
        //state.product.rows = addRfq(product.countries, state.product.rows)
        state.features.ma = setFeatureMA( action.payload.data, state.product.id, product.countries )
      } else {
        state.features.ma = {};
        state.product.ma = null;
      }
    } );
    builder.addCase( saveMarketAssociations.fulfilled, ( state, action ) => {
      if ( action.payload.data ) {
        state.product.saveData = action.payload.data
        state.isDataUpdated = false;
        if( action.payload.data.name ) {
          state.selectedWorkItem = { name: action.payload.data.name, id: action.payload.data.workItemId }
          state.workItems = [...state.workItems, state.selectedWorkItem]
        } 


        //Remove failed effectivities from the features status 
        const effectivityResult = action.payload.data.effectivityResult;
        if( Object.keys( effectivityResult ).length !== 0 ) {
          if(state.product.id in effectivityResult && effectivityResult [state.product.id].toLowerCase().includes('failed')){
            state.productStatus = state.productStatus == FeatureStatus.Active?FeatureStatus.InActive:FeatureStatus.Active;
          }
          Object.keys( effectivityResult ).forEach( ( key )=>{
            if( effectivityResult[key].toLowerCase().includes( 'failed' ) && Object.keys( state.features.status.initial ).includes( key ) ) {
              state.features.status.updated[key] = state.features.status.initial[key]
            }
          } )
          state.features.status.initial = state.features.status.updated;
        }
       
      }
    } )
  }
} )

export const { updateToInitialFeatureMA, resetProductData, revertMarketAssociations, setProduct,
  updateProductMarkets, updateFeatureMarkets,
  setWorkItem, resetIsDataUpdated, setResetDataFlag, setNewWorkItem, resetFeatureMA,
  setEditMode,setProductStatus,setFeatureStatus,updateFeatureStatus } = ProcosSlice.actions

export const procosSettings = ( state: IProcosState ) => state

export default ProcosSlice.reducer