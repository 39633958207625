import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CategoryApi } from '../../api/CategoryApi';
import { ICategoryState } from '../../../types/IComponentTypes';
import { AppState } from '../AppStore';
import { IFeature, IMasterView, IProductCode, IProductType } from '../../../types';
import { getMasterViewWithflag } from '../../services/CategoryHelper';

// It's perform the role to get the masterview from API
export const getMasterView = createAsyncThunk(
  'category/getMasterView',
  async ( params: IProductCode ) => {
    const response = await CategoryApi.getMaster( params );
    return response;
  }
)

// It's perform the role to get the feature family list from API
export const getFeatureFamilyList = createAsyncThunk(
  'category/getFeatureFamilyList',
  async ( params: IProductType ) => {
    const response = await CategoryApi.getFeatureFamilies( params );
    return response;
  }
)

// It's perform the role to get the category list from API
export const getCategoryList = createAsyncThunk(
  'category/getCategoryList',
  async () => {
    const response = await CategoryApi.getCategories();
    return response;
  }
)

// It's perform the role to save the master view to the API
export const saveMaster = createAsyncThunk(
  'category/saveMaster',
  async ( masterView: IMasterView ) => {
    const response = await CategoryApi.saveMaster( masterView );
    return response;
  }
)

// Define the initial state using that type
const initialValueForSection = {
  id: '',
  name: '',
  contents: [],
  sections: []
};
const initialState: ICategoryState = {
  masterView: {
    productCode: '',
    sections: []
  },
  categories: [],
  featureFamily: [],
  assigned: initialValueForSection,
  unassigned: [],
  editView: false,
  showAddCategoryDialog: false,
  sectionForAddSubSection: initialValueForSection,
  isCategoryDataModified: false
}

export const categorySlice = createSlice( {
  name: 'category',
  initialState,
  reducers: {
    resetToInitialState: ( state: ICategoryState ) => {
      state = initialState;
      return state;
    },
    updateMasterView: ( state: ICategoryState, action: PayloadAction<any> ) => {
      const { payload } = action;
      state.masterView = payload;
    },
    updateAssignedFeature: ( state: ICategoryState, action: PayloadAction<any> ) => {
      const { payload } = action;
      state.assigned = payload;
    },
    updateUnassignedFeature: ( state: ICategoryState, action: PayloadAction<any> ) => {
      const { payload } = action;
      state.unassigned = payload;
    },
    deleteSection: ( state: ICategoryState, action: PayloadAction<any> ) => {      
      const { payload } = action;
      state.masterView = payload;
      state.isCategoryDataModified = true;
    },
    editView: ( state: ICategoryState, action: PayloadAction<any> ) => {
      const { payload } = action;
      state.editView = payload;
    },
    showAddCategoryDialog: ( state: ICategoryState, action: PayloadAction<any> ) => {
      const { payload } = action;
      state.sectionForAddSubSection = payload.section;
      state.showAddCategoryDialog = payload.flag;
    },
    addCategory: ( state: ICategoryState, action: PayloadAction<any> ) => {
      const data = action.payload;
      const { masterView } = state;
      masterView.sections = data;
      state.masterView = masterView;
      state.isCategoryDataModified = true;
    },
    updateCategoryModify: ( state: ICategoryState ) => {
      state.isCategoryDataModified = true;
    }
  },
  extraReducers: ( builder ) => {
    builder
      // GET MASTER
      .addCase( getMasterView.fulfilled, ( state: ICategoryState, action: PayloadAction<any> ) => {
        if ( action.payload.data != undefined && action.payload.data.productCode != undefined ) {
          const masterViewData = getMasterViewWithflag(action.payload.data, action.payload.data.sections[0]);
          if(masterViewData != null) {
            state.masterView = masterViewData;
          };
        } else {          
          state.masterView = initialState.masterView;          
        }
        
        state.assigned = initialValueForSection;
        state.editView = false;
        state.isCategoryDataModified = false;
      } )

      // GET FAMILY FEATURE
      .addCase( getFeatureFamilyList.fulfilled, ( state: ICategoryState, action: PayloadAction<any> ) => {
        if( action.payload.data != undefined && action.payload.data.length > 0 ) {
          action.payload.data = action.payload.data.filter( ( feature:IFeature ) =>feature.code !== 'PMP' )
          state.featureFamily = action.payload.data;
        } else {
          state.featureFamily = [];
        }
        
      } )

      // GET CATEGORY
      .addCase( getCategoryList.fulfilled, ( state: ICategoryState, action: PayloadAction<any> ) => {
        if( action.payload.data != undefined && action.payload.data.length > 0 ) {
          state.categories = action.payload.data;
        } else {
          state.categories = [];
        }
      } )

      // SAVE CATEGORY
      .addCase( saveMaster.fulfilled, ( state: ICategoryState, action: PayloadAction<any> ) => {
        state.editView = false;
        if( action.payload != undefined && action.payload?.productCode ) {
          state.masterView = action.payload;
          state.isCategoryDataModified = false;
        }
      } )
  },
} )

export const { deleteSection, updateMasterView, editView, showAddCategoryDialog, 
  addCategory, updateAssignedFeature, updateUnassignedFeature, resetToInitialState, updateCategoryModify } = categorySlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCategories = ( state: AppState ) => state.category;

export default categorySlice.reducer;

