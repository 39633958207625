import AxiosClient from './AxiosClient';
import { appSettings } from '../settings';
import ApiUrls from '../services/APIUrl';
import { handleError, handleSuccess } from '../services/DataHelperFunctions';
import { IUserDetails } from '../../types/IRequestTypes';


export const UserApi = {

  // This api call will returns the logged in user details from the DB 
  getLoggedInUserDetails: ( action: { token: IUserDetails } ) => {
    return AxiosClient
      .get( ApiUrls.readUserDetails,
        {
          baseURL: appSettings.DisEndpoint,
          headers: { 'Authorization': `Bearer ${action.token}` }
        }
      ).then( ( response ) => {
        return handleSuccess( response )
      } ).catch( ( err ) => {
        return handleError( err )
      } )
  }
}