
import AD from '../../public/svg/flags/ad.svg';
import AE from '../../public/svg/flags/ae.svg';
import AF from '../../public/svg/flags/af.svg';
import AG from '../../public/svg/flags/ag.svg';
import AI from '../../public/svg/flags/ai.svg';
import AL from '../../public/svg/flags/al.svg';
import AM from '../../public/svg/flags/am.svg';
import AN from '../../public/svg/flags/an.svg';
import AO from '../../public/svg/flags/ao.svg';
import AQ from '../../public/svg/flags/aq.svg';
import AR from '../../public/svg/flags/ar.svg';
import AS from '../../public/svg/flags/as.svg';
import AT from '../../public/svg/flags/at.svg';
import AU from '../../public/svg/flags/au.svg';
import AW from '../../public/svg/flags/aw.svg';
import AX from '../../public/svg/flags/ax.svg';
import AZ from '../../public/svg/flags/az.svg';
import BA from '../../public/svg/flags/ba.svg';
import BB from '../../public/svg/flags/bb.svg';
import BD from '../../public/svg/flags/bd.svg';
import BE from '../../public/svg/flags/be.svg';
import BF from '../../public/svg/flags/bf.svg';
import BG from '../../public/svg/flags/bg.svg';
import BH from '../../public/svg/flags/bh.svg';
import BI from '../../public/svg/flags/bi.svg';
import BJ from '../../public/svg/flags/bj.svg';
import BL from '../../public/svg/flags/bl.svg';
import BM from '../../public/svg/flags/bm.svg';
import BN from '../../public/svg/flags/bn.svg';
import BO from '../../public/svg/flags/bo.svg';
import BQ from '../../public/svg/flags/bq.svg';
import BR from '../../public/svg/flags/br.svg';
import BS from '../../public/svg/flags/bs.svg';
import BT from '../../public/svg/flags/bt.svg';
import BV from '../../public/svg/flags/bv.svg';
import BW from '../../public/svg/flags/bw.svg';
import BY from '../../public/svg/flags/by.svg';
import BZ from '../../public/svg/flags/bz.svg';
import CA from '../../public/svg/flags/ca.svg';
import CC from '../../public/svg/flags/cc.svg';
import CD from '../../public/svg/flags/cd.svg';
import CF from '../../public/svg/flags/cf.svg';
import CG from '../../public/svg/flags/cg.svg';
import CH from '../../public/svg/flags/ch.svg';
import CI from '../../public/svg/flags/ci.svg';
import CK from '../../public/svg/flags/ck.svg';
import CL from '../../public/svg/flags/cl.svg';
import CM from '../../public/svg/flags/cm.svg';
import CN from '../../public/svg/flags/cn.svg';
import CO from '../../public/svg/flags/co.svg';
import CR from '../../public/svg/flags/cr.svg';
import CU from '../../public/svg/flags/cu.svg';
import CV from '../../public/svg/flags/cv.svg';
import CW from '../../public/svg/flags/cw.svg';
import CX from '../../public/svg/flags/cx.svg';
import CY from '../../public/svg/flags/cy.svg';
import CZ from '../../public/svg/flags/cz.svg';
import DE from '../../public/svg/flags/de.svg';
import DJ from '../../public/svg/flags/dj.svg';
import DK from '../../public/svg/flags/dk.svg';
import DM from '../../public/svg/flags/dm.svg';
import DO from '../../public/svg/flags/do.svg';
import DZ from '../../public/svg/flags/dz.svg';
import EC from '../../public/svg/flags/ec.svg';
import EE from '../../public/svg/flags/ee.svg';
import EG from '../../public/svg/flags/eg.svg';
import EH from '../../public/svg/flags/eh.svg';
import ER from '../../public/svg/flags/er.svg';
import ES from '../../public/svg/flags/es.svg';
import ET from '../../public/svg/flags/et.svg';
import FI from '../../public/svg/flags/fi.svg';
import FJ from '../../public/svg/flags/fj.svg';
import FK from '../../public/svg/flags/fk.svg';
import FM from '../../public/svg/flags/fm.svg';
import FO from '../../public/svg/flags/fo.svg';
import FR from '../../public/svg/flags/fr.svg';
import GA from '../../public/svg/flags/ga.svg';
import GB from '../../public/svg/flags/gb.svg';
import GD from '../../public/svg/flags/gd.svg';
import GE from '../../public/svg/flags/ge.svg';
import GF from '../../public/svg/flags/gf.svg';
import GG from '../../public/svg/flags/gg.svg';
import GH from '../../public/svg/flags/gh.svg';
import GI from '../../public/svg/flags/gi.svg';
import GL from '../../public/svg/flags/gl.svg';
import GM from '../../public/svg/flags/gm.svg';
import GN from '../../public/svg/flags/gn.svg';
import GP from '../../public/svg/flags/gp.svg';
import GQ from '../../public/svg/flags/gq.svg';
import GR from '../../public/svg/flags/gr.svg';
import GS from '../../public/svg/flags/gs.svg';
import GT from '../../public/svg/flags/gt.svg';
import GU from '../../public/svg/flags/gu.svg';
import GW from '../../public/svg/flags/gw.svg';
import GY from '../../public/svg/flags/gy.svg';
import HK from '../../public/svg/flags/hk.svg';
import HM from '../../public/svg/flags/hm.svg';
import HN from '../../public/svg/flags/hn.svg';
import HR from '../../public/svg/flags/hr.svg';
import HT from '../../public/svg/flags/ht.svg';
import HU from '../../public/svg/flags/hu.svg';
import ID from '../../public/svg/flags/id.svg';
import IE from '../../public/svg/flags/ie.svg';
import IL from '../../public/svg/flags/il.svg';
import IM from '../../public/svg/flags/im.svg';
import IN from '../../public/svg/flags/in.svg';
import IO from '../../public/svg/flags/io.svg';
import IQ from '../../public/svg/flags/iq.svg';
import IR from '../../public/svg/flags/ir.svg';
import IS from '../../public/svg/flags/is.svg';
import IT from '../../public/svg/flags/it.svg';
import JE from '../../public/svg/flags/je.svg';
import JM from '../../public/svg/flags/jm.svg';
import JO from '../../public/svg/flags/jo.svg';
import JP from '../../public/svg/flags/jp.svg';
import KE from '../../public/svg/flags/ke.svg';
import KG from '../../public/svg/flags/kg.svg';
import KH from '../../public/svg/flags/kh.svg';
import KI from '../../public/svg/flags/ki.svg';
import KM from '../../public/svg/flags/km.svg';
import KN from '../../public/svg/flags/kn.svg';
import KP from '../../public/svg/flags/kp.svg';
import KR from '../../public/svg/flags/kr.svg';
import KW from '../../public/svg/flags/kw.svg';
import KY from '../../public/svg/flags/ky.svg';
import KZ from '../../public/svg/flags/kz.svg';
import LA from '../../public/svg/flags/la.svg';
import LB from '../../public/svg/flags/lb.svg';
import LC from '../../public/svg/flags/lc.svg';
import LI from '../../public/svg/flags/li.svg';
import LK from '../../public/svg/flags/lk.svg';
import LR from '../../public/svg/flags/lr.svg';
import LS from '../../public/svg/flags/ls.svg';
import LT from '../../public/svg/flags/lt.svg';
import LU from '../../public/svg/flags/lu.svg';
import LV from '../../public/svg/flags/lv.svg';
import LY from '../../public/svg/flags/ly.svg';
import MA from '../../public/svg/flags/ma.svg';
import MC from '../../public/svg/flags/mc.svg';
import MD from '../../public/svg/flags/md.svg';
import ME from '../../public/svg/flags/me.svg';
import MF from '../../public/svg/flags/mf.svg';
import MG from '../../public/svg/flags/mg.svg';
import MH from '../../public/svg/flags/mh.svg';
import MK from '../../public/svg/flags/mk.svg';
import ML from '../../public/svg/flags/ml.svg';
import MM from '../../public/svg/flags/mm.svg';
import MN from '../../public/svg/flags/mn.svg';
import MO from '../../public/svg/flags/mo.svg';
import MP from '../../public/svg/flags/mp.svg';
import MQ from '../../public/svg/flags/mq.svg';
import MR from '../../public/svg/flags/mr.svg';
import MS from '../../public/svg/flags/ms.svg';
import MT from '../../public/svg/flags/mt.svg';
import MU from '../../public/svg/flags/mu.svg';
import MV from '../../public/svg/flags/mv.svg';
import MW from '../../public/svg/flags/mw.svg';
import MX from '../../public/svg/flags/mx.svg';
import MY from '../../public/svg/flags/my.svg';
import MZ from '../../public/svg/flags/mz.svg';
import NA from '../../public/svg/flags/na.svg';
import NC from '../../public/svg/flags/nc.svg';
import NE from '../../public/svg/flags/ne.svg';
import NF from '../../public/svg/flags/nf.svg';
import NG from '../../public/svg/flags/ng.svg';
import NI from '../../public/svg/flags/ni.svg';
import NL from '../../public/svg/flags/nl.svg';
import NO from '../../public/svg/flags/no.svg';
import NP from '../../public/svg/flags/np.svg';
import NR from '../../public/svg/flags/nr.svg';
import NU from '../../public/svg/flags/nu.svg';
import NZ from '../../public/svg/flags/nz.svg';
import OM from '../../public/svg/flags/om.svg';
import PA from '../../public/svg/flags/pa.svg';
import PE from '../../public/svg/flags/pe.svg';
import PF from '../../public/svg/flags/pf.svg';
import PG from '../../public/svg/flags/pg.svg';
import PH from '../../public/svg/flags/ph.svg';
import PK from '../../public/svg/flags/pk.svg';
import PL from '../../public/svg/flags/pl.svg';
import PM from '../../public/svg/flags/pm.svg';
import PN from '../../public/svg/flags/pn.svg';
import PR from '../../public/svg/flags/pr.svg';
import PS from '../../public/svg/flags/ps.svg';
import PT from '../../public/svg/flags/pt.svg';
import PW from '../../public/svg/flags/pw.svg';
import PY from '../../public/svg/flags/py.svg';
import QA from '../../public/svg/flags/qa.svg';
import RE from '../../public/svg/flags/re.svg';
import RO from '../../public/svg/flags/ro.svg';
import RS from '../../public/svg/flags/rs.svg';
import RU from '../../public/svg/flags/ru.svg';
import RW from '../../public/svg/flags/rw.svg';
import SA from '../../public/svg/flags/sa.svg';
import SB from '../../public/svg/flags/sb.svg';
import SC from '../../public/svg/flags/sc.svg';
import SD from '../../public/svg/flags/sd.svg';
import SE from '../../public/svg/flags/se.svg';
import SG from '../../public/svg/flags/sg.svg';
import SH from '../../public/svg/flags/sh.svg';
import SI from '../../public/svg/flags/si.svg';
import SJ from '../../public/svg/flags/sj.svg';
import SK from '../../public/svg/flags/sk.svg';
import SL from '../../public/svg/flags/sl.svg';
import SM from '../../public/svg/flags/sm.svg';
import SN from '../../public/svg/flags/sn.svg';
import SO from '../../public/svg/flags/so.svg';
import SR from '../../public/svg/flags/sr.svg';
import SS from '../../public/svg/flags/ss.svg';
import ST from '../../public/svg/flags/st.svg';
import SV from '../../public/svg/flags/sv.svg';
import SX from '../../public/svg/flags/sx.svg';
import SY from '../../public/svg/flags/sy.svg';
import SZ from '../../public/svg/flags/sz.svg';
import TC from '../../public/svg/flags/tc.svg';
import TD from '../../public/svg/flags/td.svg';
import TF from '../../public/svg/flags/tf.svg';
import TG from '../../public/svg/flags/tg.svg';
import TH from '../../public/svg/flags/th.svg';
import TJ from '../../public/svg/flags/tj.svg';
import TK from '../../public/svg/flags/tk.svg';
import TL from '../../public/svg/flags/tl.svg';
import TM from '../../public/svg/flags/tm.svg';
import TN from '../../public/svg/flags/tn.svg';
import TO from '../../public/svg/flags/to.svg';
import TR from '../../public/svg/flags/tr.svg';
import TT from '../../public/svg/flags/tt.svg';
import TV from '../../public/svg/flags/tv.svg';
import TW from '../../public/svg/flags/tw.svg';
import TZ from '../../public/svg/flags/tz.svg';
import UA from '../../public/svg/flags/ua.svg';
import UG from '../../public/svg/flags/ug.svg';
import UM from '../../public/svg/flags/um.svg';
import US from '../../public/svg/flags/us.svg';
import UY from '../../public/svg/flags/uy.svg';
import UZ from '../../public/svg/flags/uz.svg';
import VA from '../../public/svg/flags/va.svg';
import VC from '../../public/svg/flags/vc.svg';
import VE from '../../public/svg/flags/ve.svg';
import VG from '../../public/svg/flags/vg.svg';
import VI from '../../public/svg/flags/vi.svg';
import VN from '../../public/svg/flags/vn.svg';
import VU from '../../public/svg/flags/vu.svg';
import WF from '../../public/svg/flags/wf.svg';
import WS from '../../public/svg/flags/ws.svg';
import YE from '../../public/svg/flags/ye.svg';
import YT from '../../public/svg/flags/yt.svg';
import ZA from '../../public/svg/flags/za.svg';
import ZM from '../../public/svg/flags/zm.svg';
import ZW from '../../public/svg/flags/zw.svg';

export const Flags = { AD, AE, AF, AG, AI, AL, AM, AN, AO, AQ, AR, AS, AT, AU, AW, AX, AZ, BA, BB, BD, BE, BF, BG, BH, BI, BJ, BL, BM, BN, BO, BQ, BR, BS, BT, BV, BW, BY, BZ, CA, CC, CD, CF, CG, CH, CI, CK, CL, CM, CN, CO, CR, CU, CV, CW, CX, CY, CZ, DE, DJ, DK, DM, DO, DZ, EC, EE, EG, EH, ER, ES, ET, FI, FJ, FK, FM, FO, FR, GA, GB, GD, GE, GF, GG, GH, GI, GL, GM, GN, GP, GQ, GR, GS, GT, GU, GW, GY, HK, HM, HN, HR, HT, HU, ID, IE, IL, IM, IN, IO, IQ, IR, IS, IT, JE, JM, JO, JP, KE, KG, KH, KI, KM, KN, KP, KR, KW, KY, KZ, LA, LB, LC, LI, LK, LR, LS, LT, LU, LV, LY, MA, MC, MD, ME, MF, MG, MH, MK, ML, MM, MN, MO, MP, MQ, MR, MS, MT, MU, MV, MW, MX, MY, MZ, NA, NC, NE, NF, NG, NI, NL, NO, NP, NR, NU, NZ, OM, PA, PE, PF, PG, PH, PK, PL, PM, PN, PR, PS, PT, PW, PY, QA, RE, RO, RS, RU, RW, SA, SB, SC, SD, SE, SG, SH, SI, SJ, SK, SL, SM, SN, SO, SR, SS, ST, SV, SX, SY, SZ, TC, TD, TF, TG, TH, TJ, TK, TL, TM, TN, TO, TR, TT, TV, TW, TZ, UA, UG, UM, US, UY, UZ, VA, VC, VE, VG, VI, VN, VU, WF, WS, YE, YT, ZA, ZM, ZW };