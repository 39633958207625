import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppRouter from './AppRouter';
import { AuthProvider } from 'oidc-react';
import { authSettings } from '../api/OidcSettings'

/**
 * Main component for authentication handling.
 * @returns {JSX.Element} the AppRouter component
 */
export const AuthComponent = () => {
  const navigate = useNavigate();
  const [state, setState] = useState( {
    configdata: {
      AuthorityUrl: '',
      ClientId: '',
      Redirect: '',
      Redirect_Silent: ''
    },
  } );

  useEffect( () => {
    setState( {
      ...state,
      configdata: authSettings,
    } );
  }, [] );


  //assign the oidc config properties using oidc settings json values
  const oidcConfig = {
    onSignIn: async () => {
      navigate( {
        pathname: '/',
      }, { replace: true } );
    },
    authority: state.configdata.AuthorityUrl,
    clientId: state.configdata.ClientId,
    automaticSilentRenew: true,
    grant_type: 'refresh_token',
    response_type: 'code token id_token',
    redirectUri: state.configdata.Redirect,
    silent_redirect_uri: state.configdata.Redirect_Silent,
    scope: 'api://' + state.configdata.ClientId + '/User.Read email openid profile',
    loadUserInfo: false,
    revokeAccessTokenOnSignout: true,
  };

  //Returns the null if authority value is not assign in oidcConfig object
  if ( !oidcConfig.authority ) {
    return null;
  }

  return (
    <AuthProvider { ...oidcConfig } >
      <AppRouter />
    </AuthProvider>
  );
};
